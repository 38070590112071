.a-news-card {
  $root: &;

  width: 309px;
  cursor: pointer;
  background: $color-base-white;
  border-radius: 4px;
  box-shadow: 0 7px 20px rgba(0, 0, 0, 0.08);

  @include sp() {
    width: 242px;
  }

  &--large {
    width: 100%;
    max-width: 800px;

    @include sp() {
      width: 100%;
    }
  }

  &__top {
    position: relative;
    overflow: hidden;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    /* height: 232px;

    @include sp() {
      height: 182px;
    } */
  } 

  &__image {
    position: relative;
    width: 309px;
    height: 232px;
    overflow: hidden;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-radius: 30px 0;

    @include sp() {
      width: 242px;
      height: 182px;
    }

    #{$root}--large & {
      width: 100%;
      height: 570px;

      @include sp() {
        width: 100%;
        height: 232px;
      }
    }

    picture {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: auto;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      transform: translate(-50%, -50%);
    }

    img {
      // width: 309px;
      // height: 232px;
      width: 100%;
      height: auto;
      transition: transform 900ms;

      @include sp() {
        width: 100%;
        height: auto;
      }

      #{$root}--large & {
        width: 100%;
        height: auto;
      }
    }
  }

  &__title {
    position: relative;
    // display: flex;
    align-items: center;
    justify-content: center;
    // height: 70px;
    // padding: 16px 18px 0;
    font-family: $font-family-robinson;
    font-size: 18px;
    font-weight: 600;
    line-height: 1.2;
    color: $color-text-gold;
    text-align: center;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;

    #{$root}--long-title & {
      // padding: 16px 4px 0;
      font-size: 17px;
      line-height: 1.2;
      
    }

    @include sp() {
      height: 120px;
    }

    #{$root}--large & {
      &::after {
        position: absolute;
        bottom: 0;
        left: 24px;
        width: calc(100% - 48px);
        height: 1px;
        margin-right: auto;
        margin-left: auto;
        content: '';
        border-bottom: 1px solid rgba($color-line-gold, 20%);
      }
    }
  }

  &__summary {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80px;
    padding: 0 8px 16px;
    margin-top: 20px;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    

    &-text {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      // height: 64px;
      // overflow: hidden;
      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
    }
  }

  &__bottom {
    border: 4px solid transparent;
    border-top: 0;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    transition: border-color 700ms;
    padding: 15px;
    
}

  &:hover {
    #{$root}__image {
      border-radius: 30px 0 ;
      img {
        transform: scale(1.1);
      }
      
}

    #{$root}__bottom {
      border-color: $color-line-gold;
    }
  }

  &--large:hover {
    #{$root}__bottom {
      border-color: transparent;
    }
  }
}