.a-image {
  $root: &;

  position: relative;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: center;

  &--left {
    text-align: left;
  }

  &--right {
    text-align: right;
  }

  &__icon-360 {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;
    width: 93px;
    height: 86px;
    transform: translate(-50%, -50%);
  }
  &__image-holder {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 20px 0 ;
    
}

  &__image {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    transition: transform 1s ease-in-out;
    transform: scale(1.02);

    img {
      width: 100%;
      height: auto;

      @include sp() {
        width: auto;
        height: 100%;
      }

      @supports (object-fit: cover) {
        display: block;
        width: 100%;
        max-width: 100%;
        height: 100%;
        object-fit: cover;

        @include sp() {
          display: block;
          width: 100%;
          max-width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    &:hover {
      transform: scale(1.1);
    }
  }

  &__caption {
    width: 100%;
    padding: 2px 0;
    margin-top: 8px;
    font-size: 12px;
    line-height: 12px;

    #{$root}--left & {
      text-align: left;
    }

    #{$root}--right & {
      text-align: right;
    }
  }
}
