.m-gallery-masonry {
  $root: &;

  &__grid {
    // max-width: 100vw;
    max-width: 1224px;
    margin: 0 auto;

    /* clearfix */
    &::after {
      display: block;
      clear: both;
      content: '';
    }

    &-sizer,
    &-item {
      width: 33.33%;
      height: 300px;
      padding: 8px;
      overflow: hidden;
      aspect-ratio: 4 / 3;
      object-fit: cover;
      cursor: pointer;

      @include sp() {
        width: 100%;
        height: auto;
        aspect-ratio: auto;
      }

      #{$root}--360 & {
        width: 50%;
        height: 320px;

        @include sp() {
          width: 100%;
          height: auto;
          aspect-ratio: auto;
        }
      }

      .a-image {
        width: 100%;
        height: 100%;
        overflow: hidden;

        img {
          position: absolute;
          top: 50%;
          left: 50%;
          width: auto;
          height: 100%;
          transform: translate3d(-50%, -50%, 0);
        }

        @include sp() {
          img {
            position: static;
            width: 100%;
            height: auto;
            transform: none;
          }
        }
      }
    }

    /* 2 columns wide */
    &-item--half {
      width: 66.66%;
      height: 600px;

      @include sp() {
        width: 100%;
        height: auto;
      }
    }
    &-item--full {
      width: 100%;
      aspect-ratio: auto;

      #{$root}--360 & {
        width: 100%;
      }

      .a-image {
        &__image {
          img {
            width: 100% !important;
            height: auto !important;
          }
        }
      }
    }

    &-item--bottom {
      .a-image {
        &__image {
          img {
            top: auto !important;
            bottom: 0;
            left: 50%;
            transform: translate3d(-50%, 0, 0) !important;

            @include sp() {
              transform: none !important;
            }
          }
        }
      }
    }
  }
}
