.t-news-detail {
  position: relative;

  &__back-button {
    position: absolute;
    top: 20px;
    left: 58px;
    z-index: 1;

    @include tabletOrLess() {
      // display: none;
      top: 10px;
      left: 16px;
    }
  }

  &__article {
    margin-right: auto;
    margin-bottom: 128px;
    margin-left: auto;
  }

  &__heading {
    max-width: 880px;

    .a-heading__title {
      font-size: 32px;
      text-transform: none;
      letter-spacing: 0;

      @include sp() {
        padding-right: 0;
        padding-left: 0;
        font-size: 24px;
      }
    }
  }

  &__date {
    margin-top: 24px;
    text-align: center;
  }

  &__download-list {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;

    .a-button {
      align-items: center;
      justify-content: center;
      min-width: 240px;

      .a-icon {
        margin-top: -4px;
        margin-right: 8px;
        font-size: 16px;
      }
    }
  }

  &__content {
    max-width: 808px;
    margin-right: auto;
    margin-left: auto;
    text-align: justify;

    .a-image {
      cursor: pointer;
    }
  }

  &__primary-image {
    margin-top: 32px;

    img {
      width: 100%;
      max-width: 100%;
      height: auto;
    }
  }

  &__excerpt {
    margin-top: 24px;

    &-main {
      // font-size: 14px;
      // line-height: 21px;
      font-size: 16px;
      line-height: 1.5;

      @include sp() {
        font-size: 14px;
      }

      p {
        margin-bottom: 1em;

        .addBold{
          color: #000000;
        }

        img {
          display: block;
          width: auto;
          max-width: 100%;
          height: auto;
          margin-right: auto;
          margin-left: auto;
          border-radius: 20px 0 20px 0;
        }

        a {
          color: $color-primary-blue;
        }
      }

      .o-qsncc-data-table {
        margin-bottom: 32px;
        table-layout: fixed;

        tr {
          td {
            line-height: 1.625;
            vertical-align: top;

            @include sp() {
              font-size: 14px;
            }
          }
        }
        &__label {
          width: 290px;

          @include tablet() {
            width: 190px;
          }

          @include sp() {
            width: 128px;
            padding-bottom: 8px;
          }
        }
        &__value {

          @include sp() {
            padding-bottom: 8px;
            font-size: 13px;
          }

          p {
            margin-bottom: 0.25em;
          }
          ul {
            padding-left: 10px;
            li {
              position: relative;
              padding-left: 20px;

              &::before {
                position: absolute;
                top: 0;
                left: 0;
                content: '◆';
              }
            }
          }
        }
      }
    }
  }

  &__other-images {
    margin-top: 32px;
  }

  &__gallery-item {
    margin-top: 32px;
  }
}
