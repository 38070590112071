.m-location-map-info-card {
  $root: &;

  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 450px;
  padding: 24px;

  @include tablet() {
    max-width: 320px;
    padding: 12px;
  }

  @include sp() {
    width: 100%;
    max-width: none;
    padding-top: 40px;
    padding-bottom: 76px;
  }

  &--logo {
    padding-top: 65px;

    @include tablet() {
      padding-top: 50px;
    }

    @include sp() {
      padding-top: 50px;
    }
  }

  &__logo {
    position: absolute;
    top: -8px;
    left: 24px;
    order: 1;
    height: auto;
    max-height: 57px;

    @include tablet() {
      max-height: 40px;
    }

    @include sp() {
      top: 0;
    }

    img {
      max-height: 100%;

      @include tablet() {
        height: 40px;
      }

      @include sp() {
        width: auto;
        height: 40px;
      }
    }
  }
  &__image {
    order: 2;
    width: 100%;
    margin-bottom: 16px;

    img {
      width: 100%;
      height: auto;
      max-height: 200px;

      @include tablet() {
        height: auto;
        max-height: none;
      }

      @include sp() {
        max-height: none;
      }
    }
  }
  &__title {
    order: 3;
    margin-bottom: 12px;
    font-family: $font-family-bai-jamjuree;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: $color-text-black;
  }
  &__description {
    order: 5;
    margin-bottom: 14px;
    font-size: 16px;
    line-height: 1.5;
    color: $color-text-black;

    @include tablet() {
      font-size: 14px;
      line-height: 1.5;
    }

    @include sp() {
      margin-bottom: 0;
    }
  }
  &__info {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    order: 6;
    width: 100%;

    @include tablet() {
      .a-view-more {
        font-size: 14px;
      }
    }

    @include sp() {
      flex-direction: column;
      align-items: flex-start;
      order: 4;
    }

    &-list {
      display: flex;
      flex-direction: column;

      @include sp() {
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 16px;
      }

      .a-location-info-item {
        margin-bottom: 16px;

        &:last-child {
          margin-bottom: 0;
        }

        @include sp() {
          margin-bottom: 0;
        }
      }
    }

    &-map {
      // margin-left: auto;

      @include sp() {
        position: absolute;
        bottom: 28px;
        left: 0;
        width: 100%;
        text-align: center;
      }
    }
  }
}
