.m-location-map-mini {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  @include tabletOrMore() {
    display: none;
  }

  &__map {
    width: 100%;

    img {
      width: 100%;
    }
  }

  &__poi {
    &-list {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    &-item {
      position: absolute;
      top: 0;
      left: 0;
      pointer-events: none;
      transform: translate3d(-50%, -50%, 0);

      &--action {
        width: 48px;
        height: 48px;
        pointer-events: inherit;
      }
    }
  }
}
