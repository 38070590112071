.m-venue-info-block {
  $root: &;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 10px;

    @include sp() {
      justify-content: center;
      margin-bottom: 16px;
    }

    .a-icon {
      width: 40px;
      height: 40px;
      color: $color-grey-dark-2;
    }
  }

  &__title {
    width: 100%;
    margin-bottom: 40px;
    font-size: 18px;
    line-height: 27px;
    color: $color-text-black;

    @include sp() {
      margin-bottom: 24px;
      text-align: center;
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;

    @include sp() {
      justify-content: center;
    }

    .a-venue-info-block-item {
      margin-right: 120px;

      @include tablet() {
        margin-right: 60px;
      }

      @include sp() {
        width: 33%;
        margin-right: 0;
        margin-bottom: 28px;
      }

      &:last-child {
        // margin-right: 0;
      }

      #{$root}--icon & {
        margin-right: 48px;

        @include sp() {
          margin-right: 0;
        }
      }
    }
  }
}
