.t-contact-us {
  &__top {
    padding-top: 40px;
    padding-bottom: 64px;
    text-align: center;
    border-bottom: 1px solid rgba($color-line-gold, 0.2);

    h2 {
      margin-bottom: 32px;
      font-size: 24px;
      line-height: 33px;
      color: $color-text-gold;
      // text-transform: uppercase;

      @include sp() {
        font-size: 18px;
        line-height: 27px;
      }
    }
  }

  &__form {
    position: relative;
    max-width: 850px;
    margin-top: 32px;

    &-loading {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.85);

      &-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 70px;
        transform: translate(-50%, -50%);
      }
    }

    .o-contact-form__form-holder {
      display: none;
      max-width: 820px;
      margin-right: auto;
      margin-left: auto;
    }
  }

  &__map {
    padding-top: 80px;
    padding-bottom: 90px;
  }
}
