.o-getting-here {
  $root: &;

  display: flex;
  align-items: center;
  justify-content: center;
  // width: 100%;
  // max-width: 848px;
  width: 950px;

  @include tabletOrLess() {
    width: 100vw;
  }

  .m-tabs__tab {
    .a-tab-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      height: 100%;

      &__icon {
        flex: 1;
      }

      span {
        display: flex;
        flex: 2;
        align-items: center;
        justify-content: center;
      }
    }
  }

  &__item {
    padding-right: 16px;
    padding-left: 16px;
    font-size: 20px;
    line-height: 30px;
    color: $color-text-grey;
    text-align: center;

    &-text {
      // font-family: $font-family-robinson;
      font-family: $font-family-bai-jamjuree;
      font-size: 18px;

      p {
        margin-right: auto;
        margin-bottom: 2em;
        margin-left: auto;

        &:last-child {
          margin-bottom: 0;
        }
      }

      @include sp() {
        font-size: 14px;
        line-height: 1.5;
      }
    }

    &--mrt {
      #{$root}__item-text {
        p {
          &:nth-child(1) {
            max-width: 720px;
            #{$root}--th & {
              max-width: 588px;
            }
            @include sp() {
              max-width: 300px;

              #{$root}--th & {
                max-width: 280px;
              }
            }
          }
          &:nth-child(2) {
            @include tablet() {
              max-width: 526px;
            }
            @include sp() {
              max-width: 280px;
            }
          }
        }
      }
    }

    &--bts {
      #{$root}__item-text {
        p {
          &:nth-child(1) {
            #{$root}--th & {
              max-width: 820px;
            }
            @include tablet() {
              max-width: 560px;
            }
            @include sp() {
              max-width: 300px;
              #{$root}--th & {
                max-width: 324px;
              }
            }
          }
          &:nth-child(2) {
            max-width: 710px;
            @include sp() {
              max-width: 290px;
            }
          }
        }
      }
    }

    &--bus {
      #{$root}__item-text {
        p {
          &:nth-child(1) {
            @include tablet() {
              max-width: 560px;
            }
            @include sp() {
              max-width: 285px;
            }
          }
        }
      }
    }

    &--car {
      margin-bottom: 60px;
      #{$root}__item-text {
        p {
          &:nth-child(1) {
            max-width: 680px;

            @include tablet() {
              max-width: 540px;
            }
            @include sp() {
              max-width: 298px;
              #{$root}--th & {
                max-width: 285px;
              }
            }
          }
          &:nth-child(2) {
            max-width: 660px;
            #{$root}--th & {
              max-width: 715px;
            }
            @include tablet() {
              #{$root}--th & {
                max-width: 580px;
              }
            }
            @include sp() {
              max-width: 296px;
              #{$root}--th & {
                max-width: 320px;
              }
            }
          }
          &:nth-child(3) {
            max-width: 800px;
            @include tablet() {
              #{$root}--th & {
                max-width: 570px;
              }
            }
            @include sp() {
              max-width: 290px;
              #{$root}--th & {
                max-width: 320px;
              }
            }
          }
          &:nth-child(4) {
            #{$root}--th & {
              width: 750px;
            }
            @include tablet() {
              max-width: 550px;
            }
            @include sp() {
              max-width: 300px;
              #{$root}--th & {
                max-width: 300px;
              }
            }
          }
        }
      }
    }

    &--airport {
      #{$root}__item-text {
        p {
          &:nth-child(1) {
            max-width: 755px;
            #{$root}--th & {
              max-width: 728px;
            }
            @include tablet() {
              max-width: 526px;
              #{$root}--th & {
                max-width: 630px;
              }
            }
            @include sp() {
              max-width: 280px;
              #{$root}--th & {
                max-width: 285px;
              }
            }
          }
          &:nth-child(2) {
            max-width: 720px;
            #{$root}--th & {
              max-width: 740px;
            }
            @include tablet() {
              max-width: 520px;
              #{$root}--th & {
                max-width: 530px;
              }
            }
            @include sp() {
              max-width: 300px;
              #{$root}--th & {
                max-width: 285px;
              }
            }
          }
          &:nth-child(3) {
            max-width: 705px;
            @include tablet() {
              max-width: 470px;
            }
            @include sp() {
              max-width: 322px;
              #{$root}--th & {
                max-width: 285px;
              }
            }
          }
        }
      }
    }

    &--parking {
      #{$root}__item-text {
        p {
          &:nth-child(1) {
            max-width: 710px;
            #{$root}--th & {
              max-width: 700px;
            }
            @include tablet() {
              max-width: 560px;
            }
            @include sp() {
              max-width: 312px;
              #{$root}--th & {
                max-width: 304px;
              }
            }
          }
          &:nth-child(2) {
            max-width: 730px;
            #{$root}--th & {
              max-width: 630px;
            }
            @include tablet() {
              max-width: 540px;
              #{$root}--th & {
                max-width: 610px;
              }
            }
            @include sp() {
              max-width: 280px;
              #{$root}--th & {
                max-width: 270px;
              }
            }
          }
        }
      }
    }

    @include sp() {
      font-size: 16px;
      font-weight: 500;
      line-height: 30px;
      color: $color-text-grey;
    }

    strong {
      display: inline-block;
      font-weight: 600;
    }

    &-logo {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 40px;
      font-family: $font-family-robinson;
      font-size: 22px;
      font-weight: bold;
      line-height: 33px;
      // color: $color-text-black;

      @include sp() {
        margin-bottom: 32px;
      }

      .a-logo {
        margin-right: 16px;
      }
    }
  }
}
