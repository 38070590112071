.a-option-item {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 288px;
  height: 60px;
  font-family: $font-family-robinson;
  font-size: 18px;
  line-height: 47px;
  color: $color-text-black;
  cursor: pointer;
  background: $color-base-white;
  border: 4px solid transparent;
  border-radius: 6px;
  box-shadow: 0 7px 20px rgba(0, 0, 0, 0.17);
  transition: color 400ms, border-color 400ms;

  @include sp() {
    max-width: none;
    height: 44px;
    font-size: 18px;
    line-height: 27px;
    border-width: 2px;
  }

  &--active {
    color: $color-text-gold;
    border-color: $color-line-gold;
  }
}
