.o-map {
  display: flex;
  align-items: center;
  justify-content: center;

  &__map {
    width: 100%;
    height: 100%;
    min-height: 338px;
  }
}
