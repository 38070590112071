.a-floor-indicator {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 20px;

  @include sp() {
    padding-top: 0;
  }

  &__floor {
    position: relative;
    margin-bottom: -14px;
    cursor: pointer;
    transition: margin-top 400ms, margin-bottom 400ms;

    &--disabled {
      cursor: not-allowed;
    }

    @include sp() {
      pointer-events: none;
      cursor: default;
    }

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &:hover {
      margin-top: 14px;
      margin-bottom: 0;

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
