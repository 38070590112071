.a-poi-card {
  $root: &;

  width: 309px;
  cursor: pointer;
  background: $color-base-white;
  border-radius: 4px;
  box-shadow: 0 7px 20px rgba(0, 0, 0, 0.08);

  @include sp() {
    width: 242px;
  }

  &__top {
    position: relative;
    height: 232px;

    @include sp() {
      height: 182px;
    }
  }

  &__image {
    width: 309px;
    height: 232px;
    overflow: hidden;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;

    @include sp() {
      width: 242px;
      height: 182px;
    }

    img {
      width: 309px;
      height: 232px;
      transition: transform 700ms;

      @include sp() {
        width: 242px;
        height: 182px;
      }
    }
  }

  &__distance {
    position: absolute;
    right: 8px;
    bottom: 8px;
    padding: 5px 9px 3px 8px;
    background: $color-base-white;
    border-radius: 4px;

    .a-distance-text__value {
      font-size: 12px;
      font-weight: normal;
    }
  }

  &__title {
    display: flex;
    align-items: center;
    justify-content: center;
    // height: 120px;
    height: 90px;
    padding: 0 16px;
    font-family: $font-family-robinson;
    font-size: 18px;
    font-weight: 600;
    // line-height: 27px;
    color: $color-text-gold;
    text-align: center;
    word-wrap: break-word;

    #{$root}--th & {
      height: 120px;
    }
  }

  &__summary {
    height: 80px;
    padding: 0 12px 16px;
    font-size: 13px;
    line-height: 1.5;
    text-align: center;

    &-text {
      height: 64px;
      overflow: hidden;
    }
  }

  &:hover {
    #{$root}__image {
      img {
        transform: scale(1.2);
      }
    }
  }
}
