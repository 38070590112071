.a-back-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-family: $font-family-robinson;
  font-size: 18px;
  line-height: 40px;
  color: $color-text-gold;
  cursor: pointer;

  .a-icon {
    margin-right: 4px;
  }
}
