.o-contact-form {
  padding-bottom: 64px;

  &__form {
    display: flex;
    flex-direction: column;
    align-items: stretch;

    &-content {
      display: flex;
      width: 100%;
      @include sp() {
        flex-direction: column;
      }
    }
    &-block {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 0 13px;
      @include sp() {
        padding: 0;
      }
    }
    &-action {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 44px;
    }
  }
}
.o-contact-form__info {
  padding: 50px;
  // margin-bottom: 30px;
  border-bottom: 1px solid #cccccc;
}
.o-contact-form__info:nth-child(3) .m-contact-info-item__tit2,
.o-contact-form__info:nth-child(3) .m-contact-info-item__content2 {
  display: none !important;
}
.o-contact-QR{
  padding: 50px;
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #cccccc;
  &__content {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;
    width: 100%;
    max-width: 600px;
    min-height: 180px;
    max-height: 400px;
    text-align: center;
    background: $color-base-white;
    transform: translate3d(-50%, -50%, 0);
    padding-bottom: 20px;

    &-title {
      margin-top: 20px;
      font-family: $font-family-robinson;
      font-size: 18px;
      line-height: 27px;
      color: $color-text-gold;
    }
    &-description {
      margin-top: 8px;
      font-size: 14px;
      line-height: 20px;
    }
    &-button {
      Button{
        border: none !important;
        // padding: 10px;
        &:hover{
          background-color: $color-primary-ivory;
        }
      }
      display: flex;
      justify-content: end;
    }
  }
}

