.m-option-list {
  display: flex;
  align-items: center;
  justify-content: center;

  .a-option-item {
    margin-right: 27px;

    @include sp() {
      margin-right: 0;
      margin-bottom: 12px;
    }

    &:last-child {
      margin-right: 0;
      margin-bottom: 0;
    }
  }

  @include sp() {
    flex-direction: column;
  }

  &--horizontal {
    @include sp() {
      flex-direction: row;

      .a-option-item {
        margin-right: 8px;
        margin-bottom: 0;
        font-size: 16px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}
