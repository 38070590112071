.m-contact-info-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  &__header {
    margin-bottom: 16px;
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 16px;

    @include tabletOrLess() {
      flex-direction: column;
      justify-content: flex-start;
    }

    &-item {
      font-family: $font-family-robinson;
      font-size: 16px;
      line-height: 40px;
      color: $color-text-blue;

      @include tabletOrLess() {
        margin-bottom: 16px;
        font-size: 14px;
        line-height: 1.5;
      }

      &::after {
        margin-right: 10px;
        margin-left: 10px;
        content: '|';

        @include tabletOrLess() {
          content: none;
        }
      }

      &:last-child {
        &::after {
          content: none;
        }
      }
    }
  }

  &__description {
    // margin-bottom: 20px;
    font-size: 16px;
    line-height: 24px;
    text-align: center;

    @include tabletOrLess() {
      font-size: 14px;
      line-height: 1.5;
    }
  }
}
