.a-subscribe-input {
  $root: &;

  display: flex;
  align-items: flex-start;
  justify-content: stretch;

  &__text-field {
    display: flex;
    flex: 1;

    .a-text-field {
      &__input {
        color: $color-text-white;
        border-bottom-color: $color-line-white;

        &::placeholder {
          color: $color-text-white;

          #{$root}--invalid & {
            color: $color-primary-error;
          }
        }
      }
    }
  }
  &__button {
    height: 44px;
    padding-top: 12px;
  }
}
