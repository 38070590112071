.a-distance-text {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &__value {
    margin-left: 4px;
    // font-weight: bold;
    // color: $color-text-black;
  }
}
