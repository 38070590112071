.a-venue-info-block-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @include sp() {
    align-items: center;
  }

  &__icon {
    margin-bottom: 24px;

    @include sp() {
      margin-bottom: 16px;
    }

    .a-icon,
    img {
      width: 40px;
      height: 40px;
      color: $color-grey-dark-2;

      @include sp() {
        width: 32px;
        height: 32px;
      }
    }
  }

  &__value {
    margin-top: 6px;
    font-family: $font-family-robinson;
    font-size: 22px;
    font-weight: bold;
    line-height: 30px;
    color: $color-text-black;

    @include sp() {
      font-size: 18px;
      line-height: 27px;
    }
  }
}
