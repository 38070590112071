$font-path: 'fonts/' !default;

/**
  * Header: English - Robinson
  */
@font-face {
  font-family: Robinson;
  font-style: normal;
  font-weight: 700;
  src: url('#{$font-path}Robinson-Medium.eot');
  src: url('#{$font-path}Robinson-Medium.eot?#iefix') format('embedded-opentype'),
    url('#{$font-path}Robinson-Medium.woff2') format('woff2'), url('#{$font-path}Robinson-Medium.woff') format('woff'),
    url('#{$font-path}Robinson-Medium.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: Robinson;
  font-style: normal;
  font-weight: 500;
  src: url('#{$font-path}Robinson-Regular.eot');
  src: url('#{$font-path}Robinson-Regular.eot?#iefix') format('embedded-opentype'),
    url('#{$font-path}Robinson-Regular.woff2') format('woff2'), url('#{$font-path}Robinson-Regular.woff') format('woff'),
    url('#{$font-path}Robinson-Regular.ttf') format('truetype');
  font-display: swap;
}

/**
  * Header: Header: Thai - KiriMaya
  */
@font-face {
  font-family: KiriMaya;
  font-style: normal;
  font-weight: 700;
  src: url('#{$font-path}KiriMaya-Bold.eot');
  src: url('#{$font-path}KiriMaya-Bold.eot?#iefix') format('embedded-opentype'),
    url('#{$font-path}KiriMaya-Bold.woff2') format('woff2'), url('#{$font-path}KiriMaya-Bold.woff') format('woff'),
    url('#{$font-path}KiriMaya-Bold.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: KiriMaya;
  font-style: normal;
  font-weight: 500;
  src: url('#{$font-path}KiriMaya-Regular.eot');
  src: url('#{$font-path}KiriMaya-Regular.eot?#iefix') format('embedded-opentype'),
    url('#{$font-path}KiriMaya-Regular.woff2') format('woff2'), url('#{$font-path}KiriMaya-Regular.woff') format('woff'),
    url('#{$font-path}KiriMaya-Regular.ttf') format('truetype');
  font-display: swap;
}

/**
  * Header: Body - Bai Jamjuree
  */
@font-face {
  font-family: 'Bai Jamjuree';
  font-style: normal;
  font-weight: 700;
  src: url('#{$font-path}BaiJamjuree-Bold.eot');
  src: url('#{$font-path}BaiJamjuree-Bold.eot?#iefix') format('embedded-opentype'),
    url('#{$font-path}BaiJamjuree-Bold.woff2') format('woff2'), url('#{$font-path}BaiJamjuree-Bold.woff') format('woff'),
    url('#{$font-path}BaiJamjuree-Bold.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Bai Jamjuree';
  font-style: italic;
  font-weight: bold;
  src: url('#{$font-path}BaiJamjuree-BoldItalic.eot');
  src: url('#{$font-path}BaiJamjuree-BoldItalic.eot?#iefix') format('embedded-opentype'),
    url('#{$font-path}BaiJamjuree-BoldItalic.woff2') format('woff2'),
    url('#{$font-path}BaiJamjuree-BoldItalic.woff') format('woff'),
    url('#{$font-path}BaiJamjuree-BoldItalic.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Bai Jamjuree';
  font-style: normal;
  font-weight: 500;
  src: url('#{$font-path}BaiJamjuree-Medium.eot');
  src: url('#{$font-path}BaiJamjuree-Medium.eot?#iefix') format('embedded-opentype'),
    url('#{$font-path}BaiJamjuree-Medium.woff2') format('woff2'),
    url('#{$font-path}BaiJamjuree-Medium.woff') format('woff'),
    url('#{$font-path}BaiJamjuree-Medium.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Bai Jamjuree';
  font-style: italic;
  font-weight: 500;
  src: url('#{$font-path}BaiJamjuree-Italic.eot');
  src: url('#{$font-path}BaiJamjuree-Italic.eot?#iefix') format('embedded-opentype'),
    url('#{$font-path}BaiJamjuree-Italic.woff2') format('woff2'),
    url('#{$font-path}BaiJamjuree-Italic.woff') format('woff'),
    url('#{$font-path}BaiJamjuree-Italic.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Bai Jamjuree';
  font-style: italic;
  font-weight: 500;
  src: url('#{$font-path}BaiJamjuree-MediumItalic.eot');
  src: url('#{$font-path}BaiJamjuree-MediumItalic.eot?#iefix') format('embedded-opentype'),
    url('#{$font-path}BaiJamjuree-MediumItalic.woff2') format('woff2'),
    url('#{$font-path}BaiJamjuree-MediumItalic.woff') format('woff'),
    url('#{$font-path}BaiJamjuree-MediumItalic.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Bai Jamjuree';
  font-style: normal;
  font-weight: 500;
  src: url('#{$font-path}BaiJamjuree-Regular.eot');
  src: url('#{$font-path}BaiJamjuree-Regular.eot?#iefix') format('embedded-opentype'),
    url('#{$font-path}BaiJamjuree-Regular.woff2') format('woff2'),
    url('#{$font-path}BaiJamjuree-Regular.woff') format('woff'),
    url('#{$font-path}BaiJamjuree-Regular.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Bai Jamjuree';
  font-style: italic;
  font-weight: 600;
  src: url('#{$font-path}BaiJamjuree-SemiBoldItalic.eot');
  src: url('#{$font-path}BaiJamjuree-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
    url('#{$font-path}BaiJamjuree-SemiBoldItalic.woff2') format('woff2'),
    url('#{$font-path}BaiJamjuree-SemiBoldItalic.woff') format('woff'),
    url('#{$font-path}BaiJamjuree-SemiBoldItalic.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Bai Jamjuree';
  font-style: normal;
  font-weight: 600;
  src: url('#{$font-path}BaiJamjuree-SemiBold.eot');
  src: url('#{$font-path}BaiJamjuree-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('#{$font-path}BaiJamjuree-SemiBold.woff2') format('woff2'),
    url('#{$font-path}BaiJamjuree-SemiBold.woff') format('woff'),
    url('#{$font-path}BaiJamjuree-SemiBold.ttf') format('truetype');
  font-display: swap;
}

/**
 * Font definition
 */
$font-family-robinson: 'Robinson', 'Helvetica', 'Arial', 'KiriMaya', sans-serif;
$font-family-kirimaya: 'KiriMaya', 'Times New Roman', sans-serif;
$font-family-bai-jamjuree: 'Bai Jamjuree', 'Helvetica', 'Arial', sans-serif;
$font-family-header: 'Robinson', 'KiriMaya', 'Helvetica', 'Arial', sans-serif;

$font-weight-regular: 500;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;
