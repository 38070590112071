.a-card-item {
  $root: &;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 352px;
  padding: 48px 24px 24px;
  cursor: pointer;
  background: $color-base-white;
  border: 4px solid transparent;
  border-radius: 6px;
  box-shadow: 0 7px 20px rgba(0, 0, 0, 0.08);
  transition: border-color 400ms;

  @include sp() {
    width: 242px;
    border: 0;
  }

  /* &--active {
    border-color: $color-line-gold;
  }

  .swiper-slide-active & {
    border-color: $color-line-gold;
  } */

  &:hover {
    border-color: $color-line-gold;
  }

  &__image {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 228px;
    overflow: hidden;
    background: $color-grey-light-3;
    border-radius: 4px;

    @include sp() {
      height: 146px;
    }

    img {
      height: 228px;
      @include sp() {
        height: 146px;
      }
    }
  }

  &__title {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100px;
    margin-top: 16px;
    font-family: $font-family-robinson;
    font-size: 18px;
    line-height: 30px;
    text-align: center;
  }

  &__date {
    width: 100%;
    padding-top: 20px;
    margin-top: 16px;
    font-size: 16px;
    line-height: 20px;
    color: $color-text-grey;
    text-align: center;
    text-transform: uppercase;
    border-top: 1px solid rgba($color-line-gold, 0.2);

    #{$root}--active & {
      border-color: $color-line-gold;
    }
    .swiper-slide-active & {
      border-color: $color-line-gold;
    }
  }
}
