$basewidth-pc: 1280px;
$WIDTH-TABLET: 992px;
$WIDTH-SP: 640px;
:root {
  --width-sp: #{$WIDTH-SP};
}

$breakpoint-large-pc: $basewidth-pc;
$breakpoint-small-pc: 1024px;
$breakpoint-sp: 768px;
$breakpoint-header-pc: 1080px;
