.a-logo {
  display: inline-block;
  width: 1em;
  height: 1em;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: top left;
  background-size: contain;

  &--large {
    width: 2em;
    height: 2em;
  }

  @mixin logo-name($name) {
    &--#{$name} {
      background-image: url('~images/logos/#{$name}.svg');
    }
  }

  @include logo-name('bmta');
  @include logo-name('bts');
  @include logo-name('mrt');
  @include logo-name('mrt-circle');
  @include logo-name('airport-raillink');
}
