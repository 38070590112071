.t-media {
  $root: &;

  .a-heading {
    .a-heading__title {
      letter-spacing: 8px;
    }
  }

  &__tabs {
    margin-top: 32px;
  }

  &__description {
    max-width: 740px;
    margin-top: 64px;
    margin-right: auto;
    margin-left: auto;
    text-align: center;

    @include sp() {
      margin-top: 32px;
    }

    &--news {
      @include tablet() {
        max-width: 510px;
      }

      @include sp() {
        max-width: 270px;
      }
    }

    &--gallery {
      @include tablet() {
        max-width: 510px;
      }

      @include sp() {
        max-width: 320px;
      }
    }
  }

  &__info {
    // min-height: 645px;
    padding-top: 64px;
    padding-bottom: 64px;

    @include sp() {
      padding-top: 32px;
    }

    &--news {
      max-width: none;
      padding-bottom: 120px;

      @include sp() {
        padding-bottom: 64px;
      }
    }

    &-news {
      max-width: 1016px;
      margin-right: auto;
      margin-left: auto;

      &-highlight {
        .a-news-card {
          margin-right: auto;
          margin-left: auto;
        }
      }

      &-carousel {
        max-width: 1032px;
        margin-top: 44px;
        margin-right: auto;
        margin-left: auto;

        @include tabletOrLess() {
          max-width: none;
          margin-right: -24px;
          margin-left: -24px;
        }

        .o-carousel__container {
          padding-right: 24px;
          padding-bottom: 76px;
          // padding-bottom: 24px;
          padding-left: 24px;

          /* .swiper-pagination {
            display: none;
          } */
          .swiper-pagination {
            position: absolute;
            z-index: 0;
          }
        }

        @include sp() {
          margin-right: -24px;
          // margin-bottom: 64px;
          margin-left: -24px;
        }
      }
    }

    &--gallery {
      max-width: 1224px;
      padding-top: 0;
      padding-bottom: 120px;

      @include sp() {
        padding-bottom: 64px;
      }
    }

    &-gallery {
      &-description {
        max-width: 912px;
        text-align: center;
      }

      &-tabs {
        margin-top: 64px;

        @include sp() {
          margin-top: 32px;
        }

        .m-tabs__content {
          padding-right: 0;
          padding-left: 0;
        }

        .m-tabs__tab-header {
          max-width: 288px;
          margin-right: auto;
          margin-left: auto;

          #{$root}--lang-th & {
            max-width: 360px;
          }
        }
      }
    }

    &--ncc-group {
      max-width: 664px;
      text-align: center;
    }

    &-ncc-group {
      &-logo {
        margin-bottom: 24px;
      }

      &-title {
        font-size: 30px;
        line-height: 45px;
        color: $color-text-gold;

        @include sp() {
          font-size: 24px;
        }
      }

      &-description {
        margin-top: 16px;

        .a-text {
          margin-right: auto;
          margin-left: auto;

          &:nth-child(1) {
            width: 592px;

            #{$root}--lang-th & {
              width: 598px;
            }

            @include tablet() {
              width: 592px;

              #{$root}--lang-th & {
                width: 598px;
              }
            }

            @include sp() {
              width: 280px;

              #{$root}--lang-th & {
                width: 280px;
              }
            }
          }

          &:nth-child(2) {
            width: 552px;
            margin-top: 2em;

            #{$root}--lang-th & {
              width: 542px;
            }

            @include tablet() {
              width: 600px;

              #{$root}--lang-th & {
                width: 600px;
              }
            }

            @include sp() {
              width: 315px;

              #{$root}--lang-th & {
                width: 315px;
              }
            }
          }
        }
      }

      &-button {
        margin-top: 32px;
      }
    }
  }
}