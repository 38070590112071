.o-data-table {
  $root: &;

  width: 100%;
  // overflow: scroll;
  font-size: 16px;
  border-spacing: 0;
  mix-blend-mode: multiply;

  @include sp() {
    font-size: 14px;
  }

  &__table {
    width: 100%;
  }

  &__header {
    $header: &;

    font-family: $font-family-robinson;
    font-weight: bold;
    color: $color-text-black;
    background-color: $color-grey-light-3;

    &-row {
      &:first-child {
        #{$header}-cell {
          padding-top: 15px;
        }
      }
      &:last-child {
        #{$header}-cell {
          padding-bottom: 24px;
        }
      }
    }

    &-cell {
      &--sticky {
        background-color: $color-grey-light-3;

        &::after {
          position: absolute;
          top: 0;
          right: -15px;
          width: 15px;
          height: 100%;
          content: none;
          background: transparent;
          background: linear-gradient(90deg, rgba(216, 216, 216, 1) 0%, rgba(238, 238, 238, 0) 100%);
          opacity: 0.44;

          #{$root}--scroll & {
            content: '';
          }
        }
      }
      &--facility {
        min-width: 190px;

        @include sp() {
          min-width: 102px;
        }
      }
      &--sqm,
      &--sqft {
        min-width: 140px;
      }
      &--dimension {
        min-width: 250px;
      }
      &--line {
        border-right: 1px solid $color-line-white;
      }
    }

    &-subtitle {
      font-family: $font-family-bai-jamjuree;
      font-size: 12px;
      font-weight: normal;
      color: $color-text-grey;

      .a-icon {
        margin-top: 15px;
        margin-bottom: 16px;
        font-size: 24px;
      }
    }
  }

  &__body {
    $body: &;

    &-cell {
      min-width: 100px;
      padding: 30px 10px 34px;
      font-weight: 600;
      text-align: center;
      background-color: $color-base-white;
      background-image: url('~images/pattern.png');
      background-repeat: repeat;
      background-attachment: fixed;
      background-position: center;
      // background-size: 90px 90px;

      &--sticky {
        background-color: $color-base-white;

        &::after {
          position: absolute;
          top: 0;
          right: -15px;
          width: 15px;
          height: 100%;
          content: none;
          background: transparent;
          background: linear-gradient(90deg, rgba(216, 216, 216, 1) 0%, rgba(238, 238, 238, 0) 100%);
          opacity: 0.44;

          #{$root}--scroll & {
            content: '';
          }
        }
      }
    }
    &-row {
      &:nth-child(2n) {
        #{$body}-cell {
          background-color: $color-grey-light-5;

          &--sticky {
            background-color: $color-grey-light-5;
          }
        }
      }
    }
  }

  &__cell-label {
    font-family: $font-family-robinson;
    font-weight: bold;
    color: $color-text-black;
    text-align: center;
  }

  &__footer {
    color: $color-text-black;

    /* background-color: $color-light-gold;
    background-image: url('~images/pattern.png');
    background-repeat: repeat;
    background-size: 90px 90px; */

    &-cell {
      font-weight: 600;
      text-align: center;
      background-color: $color-light-gold;
      background-image: url('~images/pattern.png');
      background-repeat: repeat;
      background-attachment: fixed;
      background-position: center;
      background-blend-mode: multiply;
      // background-size: 90px 90px;

      &--sticky {
        font-family: $font-family-robinson;
        font-weight: bold;
        background-color: $color-light-gold;

        &::after {
          position: absolute;
          top: 0;
          right: -15px;
          width: 15px;
          height: 100%;
          content: none;
          background: transparent;
          background: linear-gradient(90deg, rgba(130, 120, 0, 1) 0%, rgba(205, 201, 153, 0) 100%);
          opacity: 0.44;

          #{$root}--scroll & {
            content: '';
          }
        }
      }
    }

    &-label {
      padding: 44px 0;
      text-align: center;
      background-color: $color-light-gold;
    }
  }
}
