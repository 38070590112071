.a-getting-here-heading {
  display: inline-flex;
  align-items: center;
  justify-content: content;

  &__logo {
    margin-right: 16px;
  }

  &__title {
    color: $color-text-black;
  }
}
