@use "sass:math";

.a-location-map-marker {
  $root: &;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  width: 182px;
  height: 230px;
  transform-origin: bottom;

  &__button {
    cursor: pointer;
    transition: transform 250ms ease-in-out;
    transform: scale(#{math.div(100, 200)});
    transform-origin: center bottom;

    #{$root}--active & {
      transform: scale(1);
    }
  }
  &__icon {
    position: relative;
    transform: translateY(20px);
  }
  &__logo {
    position: absolute;
    top: 23px;
    left: 50%;
    width: 136px;
    height: 136px;
    overflow: hidden;
    border-radius: 50%;
    transform: translateX(-50%);

    > * {
      width: 100%;
      height: auto;
      user-select: none;
    }
  }
}
