.a-page-loading {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background: $color-base-white;

  &__loader {
    width: 70px;
    height: 186px;
  }

  &__progress {
    margin-top: 20px;
    font-size: 18px;
    font-weight: bold;
    color: $color-text-black;
  }
}
