.a-location-info-item {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 14px;
  line-height: 1.5;

  @include sp() {
    font-size: 14px;
    line-height: 18px;
  }

  &__icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 4px 10px 4px 4px;
  }
}
