.o-location-map {
  $root: &;

  position: relative;

  &__large {
    @include sp() {
      display: none;
    }
  }

  &__mini {
    display: none;

    &-map {
      &-list {
        padding-top: 30px;
        // padding-bottom: 30px;
      }
    }

    @include sp() {
      display: block;
    }
  }

  &__map {
    width: 100%;
    img {
      width: 100%;
      height: auto;
    }
  }

  &__poi {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &-list {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
      // transition: z-index 400ms;

      &-item {
        position: absolute;
        width: 1px;
        height: 1px;
        // border: 1px solid red;

        .a-location-map-marker {
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);

          @include tablet() {
            transform: translateX(-50%) scale(0.7);
          }
        }

        &--active {
          z-index: 99 !important;
        }
      }
    }
    &-transport {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
      width: 100%;
      height: 100%;

      &-item {
        position: absolute;
        width: 45px;
        height: 45px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        transform: translate3d(-22px, -22px, 0);

        @include tablet() {
          width: 30px;
          height: 30px;
          transform: translate3d(-15px, -15px, 0);
        }

        &--bts {
          background-image: url('~images/marker-lg-bts.svg');
        }

        &--mrt {
          background-image: url('~images/marker-lg-mrt.svg');
        }
        &--mrt-2 {
          background-image: url('~images/marker-lg-mrt-2.svg');
        }
      }
    }
  }

  &__info-card {
    position: absolute;
    top: 0;
    left: 0;
    // z-index: -1;
    z-index: $zindex-modal;
    padding-top: 24px;
    overflow: hidden;
    visibility: hidden;
    background: $color-base-white;
    border-color: $color-line-gold;
    border-style: solid;
    border-width: 3px;
    border-image-source: linear-gradient(
      0deg,
      rgba(185, 185, 170, 1) 0%,
      rgba(238, 238, 225, 1) 60%,
      rgba(224, 224, 198, 1) 100%
    );
    border-image-slice: 1;
    box-shadow: 4px 4px 20px rgba(161, 159, 149, 0.4);
    opacity: 0;
    // transition: opacity 400ms;
    // transition: visibility 500ms;

    @include sp() {
      display: none;
    }

    &--active {
      visibility: visible;
      // z-index: $zindex-modal;
      // opacity: 1;
    }

    &__close {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 1;
      width: 48px;
      height: 48px;
      font-size: 16px;
      cursor: pointer;
    }



    &--qsncc {
      #{$root}--th & {
        .m-location-map-info-card__description {
          max-width: 390px;
          font-size: 15px;

          @include tablet() {
            max-width: 284px;
            font-size: 12px;
          }

          @include sp() {
            max-width: none;
          }
        }
      }
    }

    &--sym {
      #{$root}--th & {
        .m-location-map-info-card__description {
          max-width: 394px;

          @include tablet() {
            max-width: 294px;
            font-size: 12px;
          }

          @include sp() {
            max-width: none;
          }
        }
      }
    }

    &--medpark {
      #{$root}--th & {
        .m-location-map-info-card__description {
          max-width: 392px;
          font-size: 15px;

          @include tablet() {
            max-width: 250px;
            font-size: 12px;
          }

          @include sp() {
            max-width: none;
          }
        }
      }
    }

    &--parq {
      #{$root}--th & {
        .m-location-map-info-card__description {
          max-width: 392px;
          font-size: 15px;

          @include tablet() {
            max-width: 278px;
            font-size: 12px;
          }

          @include sp() {
            max-width: none;
          }
        }
      }
    }

    &--fyi {
      #{$root}--th & {
        .m-location-map-info-card__description {
          max-width: 392px;

          @include tablet() {
            max-width: 284px;
            font-size: 12px;
          }

          @include sp() {
            max-width: none;
          }
        }
      }
    }

    &--onebkk {
      #{$root}--th & {
        .m-location-map-info-card__description {
          max-width: 392px;

          @include tablet() {
            max-width: 284px;
            font-size: 12px;
          }

          @include sp() {
            max-width: none;
          }
        }
      }
    }

    &--benjakitti {
      #{$root}--th & {
        .m-location-map-info-card__description {
          max-width: 392px;

          @include tablet() {
            max-width: 272px;
            font-size: 12px;
          }

          @include sp() {
            max-width: none;
          }
        }
      }
    }
  }

  &__info-bottom-sheet {
    $bottom-sheet: &;

    display: none;

    &-close {
      position: absolute;
      top: 15px;
      right: 15px;
      z-index: 1;
      width: 40px;
      height: 40px;
      font-size: 16px;

      &--logo {
        top: 20px;
      }
    }

    @include sp() {
      display: block;
    }

    &--qsncc {
      &#{$bottom-sheet}--th {
        .m-location-map-info-card__description {
          width: 296px;
        }
      }
    }

    &--benjakitti {
      &#{$bottom-sheet}--th {
        .m-location-map-info-card__description {
          width: 312px;
        }
      }
    }

    &--fyi {
      &#{$bottom-sheet}--th {
        .m-location-map-info-card__description {
          width: 300px;
        }
      }
    }

    &--parq {
      &#{$bottom-sheet}--th {
        .m-location-map-info-card__description {
          width: 302px;
        }
      }
    }

    &--medpark {
      &#{$bottom-sheet}--th {
        .m-location-map-info-card__description {
          width: 312px;
        }
      }
    }

    &--onebkk {
      &#{$bottom-sheet}--th {
        .m-location-map-info-card__description {
          width: 324px;
        }
      }
    }

    &--sym {
      &#{$bottom-sheet}--th {
        .m-location-map-info-card__description {
          width: 320px;
        }
      }
    }
  }
}
