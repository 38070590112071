.a-tab-item {
  $root: &;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px 0;
  font-family: $font-family-robinson;
  font-size: 18px;
  color: $color-text-grey;

  @include sp() {
    padding: 14px 0;
    font-size: 14px;
  }

  &--active {
    color: $color-text-gold;
  }

  &--bold {
    font-weight: bold;

    @include sp() {
      font-weight: normal;
    }
  }

  &__icon {
    margin-bottom: 14px;
    font-size: 48px;

    @include sp() {
      margin-bottom: 5px;
      font-size: 32px;
    }
  }

  &__text {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    padding: 0 10px;
  }
}
