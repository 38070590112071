.a-info-card-highlight {
  $root: &;

  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 32px 80px;
  cursor: pointer;
  background: $color-base-white;
  border-radius: 4px;
  box-shadow: 0 7px 20px rgba(0, 0, 0, 0.08);

  @include tablet() {
    padding-right: 32px;
    padding-left: 32px;
  }

  @include sp() {
    flex-direction: column;
    padding: 48px 24px;
  }

  &__image {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 450px;
    overflow: hidden;
    background: $color-grey-light-3;
    border-radius: 4px;

    @include tablet() {
      width: 304px;
    }

    @include sp() {
      width: 100%;
    }

    picture {
      width: 100%;
      transition: transform 700ms ease-in-out;
      transform: scale(1);

      #{$root}:hover & {
        transform: scale(1.1);
      }
    }

    img {
      width: 100%;
    }
  }

  &__info {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    margin-left: 27px;

    @include sp() {
      align-items: center;
      margin-top: 16px;
      margin-left: 0;
    }
  }

  &__title {
    font-family: $font-family-robinson;
    // line-height: 45px;
    font-size: 26px;
    // font-size: 30px;
    font-weight: bold;
    line-height: 1.3;
    color: $color-text-gold;
    text-transform: uppercase;

    @include sp() {
      font-size: 24px;
      line-height: 36px;
      text-align: center;
    }
  }

  &__distance {
    margin-top: 5px;
  }

  &__description {
    max-height: 94px;
    margin-top: 16px;
    font-size: 16px;
    line-height: 24px;

    &-text {
      line-height: 24px;

      @include sp() {
        text-align: center;
      }
    }

    @include tablet() {
      max-height: 72px;
    }

    @include sp() {
      max-height: 80px;
    }
  }

  &__detail {
    width: 100%;
    padding-top: 20px;
    margin-top: 20px;
    border-top: 1px solid rgba($color-line-gold, 0.2);

    #{$root}--surrounding & {
      .a-location-info-item {
        margin-top: 16px;

        &:first-child {
          margin-top: 0;
        }
      }
    }
  }
}
