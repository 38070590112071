.t-venue {
  $root: &;

  .a-heading {
    .a-heading__title {
      letter-spacing: 8px;
    }
  }

  &__description {
    max-width: 704px;
    margin-top: 32px;
    margin-right: auto;
    margin-left: auto;
    text-align: center;

    #{$root}--th & {
      max-width: 690px;
    }

    @include sp() {
      max-width: 318px;

      #{$root}--th & {
        max-width: 300px;
      }
    }
  }

  &__floor-selectors {
    padding-top: 96px;
    padding-bottom: 0;

    @include tablet() {
      padding-bottom: 180px;
    }

    @include sp() {
      padding-top: 40px;
      padding-bottom: 320px;
    }
  }
}
