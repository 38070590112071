.t-privacy-policy {
  &__detail {
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    max-width: 1225px;
    margin-top: 100px;

    @include sp() {
      flex-direction: column;
      margin-top: 32px;
    }
  }
  &__shortcuts {
    position: sticky;
    top: 48px;
    left: 0;
    display: none;
    width: 100%;
    padding: 10px 0;
    margin-bottom: 20px;
    background: $color-base-white;

    .a-select-field {
      width: 100%;
      &__input {
        &__control {
          padding-right: 48px;
        }
        &__single-value {
          font-family: $font-family-bai-jamjuree;
          font-size: 16px;
          color: $color-text-black !important;
        }
      }
    }

    @include sp() {
      display: block;
    }
  }
  &__items {
    position: sticky;
    top: 120px;
    left: 0;
    flex: 0 0 360px;
    width: 360px;
    height: calc(100vh - 100px);

    @include sp() {
      position: static;
      display: none;
      flex: 1;
      width: 100%;
      height: auto;
      margin-bottom: 32px;
    }

    &-holder {
      width: 100%;
      height: 100%;
      overflow-x: hidden;
      overflow-y: scroll;
      &::-webkit-scrollbar {
        display: none;
      }
    }

    &-list {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      width: 360px;

      @include sp() {
        width: 100%;
      }

      &-item {
        width: 360px;
        border-top: 1px solid $color-line-ivory;

        @include sp() {
          width: 100%;
        }

        &:last-child {
          border-bottom: 1px solid $color-line-ivory;
        }

        button {
          width: 100%;
          padding-top: 20px;
          padding-bottom: 20px;
          font-family: $font-family-robinson;
          font-size: 18px;
          line-height: 27px;
          color: $color-text-gold;
          text-align: left;
          cursor: pointer;

          &:hover {
            text-decoration: underline;
          }
        }

        &--active {
          /* stylelint-disable-next-line no-descending-specificity */
          button {
            font-weight: bold;
          }
        }
      }
    }
  }
  &__info {
    margin-left: 60px;

    @include sp() {
      margin-left: 0;
    }

    &-detail {
      margin-bottom: 80px;

      p {
        margin-bottom: 1em;
      }

      ol {
        padding-left: 2em;
        li {
          padding-left: 0.5em;
          margin-bottom: 1em;
          list-style-type: decimal;
        }
      }
    }
  }
}
