.o-carousel {
  $root: &;

  &__container {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .swiper-slide {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    max-width: 100%;
    font-size: 18px;
    text-align: center;

    img {
      display: block;
      width: 773px;
      // width: 100%;
      max-width: 100%;
      height: 507px;
      // height: 100%;
      object-fit: cover;

      @include sp() {
        width: 300px;
        height: 225px;
      }
    }

    &-active {
      transition: top 250ms;
    }
  }

  &--news,
  &--news-highlight,
  &--surrounding {
    #{$root}__container {
      padding-top: 28px;
    }

    .swiper-slide {
      img {
        width: 309px;
        height: 232px;
        transition: transform 700ms ease-in-out;
        object-fit: contain;
        

        @include sp() {
          width: auto;
          height: 100%;
          
}
      }

      transition: transform 700ms;

      &:hover {
        transform: translateY(-28px);

        img {
          transform: scale(1.1);
          
        }

        @include sp() {
          transform: translateY(0);
        }
      }
    }

    /* .swiper-slide-active {
      top: -28px;

      @include sp() {
        top: 0;
      }
    } */
  }

  &--news-highlight {
    max-width: 1032px;
    margin-right: auto;
    margin-left: auto;

    .o-carousel__container {
      padding-right: 16px;
      padding-left: 16px;
    }
  }

  &--news {

    /* stylelint-disable-next-line no-descending-specificity */
    .swiper-slide {

      /* stylelint-disable-next-line no-descending-specificity */
      img {
        width: 100%;
        border-radius: 30px 0;
        object-fit: cover;
      }
    }
  }

  &--surrounding {

    /* stylelint-disable-next-line no-descending-specificity */
    .swiper-slide {

      /* stylelint-disable-next-line no-descending-specificity */
      img {
        width: 304px;
        height: 228px;
      }
    }
  }

  &--hero {

    /* stylelint-disable-next-line no-descending-specificity */
    .swiper-slide {
      width: 100vw;
      height: 100vh;

      /* stylelint-disable-next-line value-no-vendor-prefix */
      min-height: -webkit-fill-available;
    }

    .swiper-pagination {
      position: absolute !important;
      bottom: 0;
      left: 0;
      justify-content: flex-start !important;
      width: 100%;
      padding-left: 77px;
      margin: 0;

      @include sp() {
        padding-left: 24px;
      }
    }
  }

  .swiper-container-horizontal>.swiper-pagination-bullets {
    // bottom: 24px;
    bottom: 0;
  }

  // --- SWIPER PAGINATION
  .swiper-pagination {
    position: static;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 44px;
    margin-top: 32px;
  }

  .swiper-pagination-bullet {
    position: relative;
    box-sizing: border-box;
    width: 8px;
    height: 8px;
    margin: 0 6px !important;
    background: $color-grey-light-2;
    border-radius: 0;
    opacity: 1;
    transition: width 0.25s linear, height 0.25s linear;

    &::after {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 8px;
      height: 8px;
      content: '';
      background: $color-grey-light-2;
      transition: width 0.25s linear, height 0.25s linear, transform 0.25s linear;
      transform: translate3d(0, 0, 0);
    }
  }

  .swiper-pagination-bullet-active {
    width: 12px;
    // height: 20px;
    height: 8px;
    background: $color-base-gold;
    border: 0;

    &::after {
      width: 12px;
      height: 20px;
      background: $color-base-gold;
      transform: translate3d(0, 6px, 0);
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    top: auto;
    bottom: 0;
    z-index: $zindex-menu;
    display: flex;
    width: 44px;
    height: 44px;
    border: 1px solid rgba($color-base-gold, 0.2);
    border-radius: 0;

    @include tabletOrLess() {
      display: none;
    }

    .a-icon {
      width: 24px;
      height: 24px;
      color: $color-base-gold;
    }

    &::after {
      display: none;
    }
  }

  .swiper-button-prev {
    left: 50%;
    transform: translateX(-250px);
  }

  .swiper-button-next {
    right: 50%;
    transform: translateX(250px);
  }

  /* .swiper-button-disabled {
    display: none;
  } */

  &--gallery,
  &--no-button {

    .swiper-button-prev,
    .swiper-button-next {
      display: none !important;
    }
  }

  &--gallery {
    .swiper-wrapper {
      padding-top: 20px;
    }

    /* stylelint-disable-next-line no-descending-specificity */
    .swiper-slide {

      /* stylelint-disable-next-line no-descending-specificity */
      img {
        width: auto;
        height: 348px;

        @include sp() {
          height: 225px;
        }
      }
    }

    .swiper-slide-active {
      transform: scale(1.05);
    }
  }

  .swiper-no-swiping {

    .swiper-button-prev,
    .swiper-button-next,
    .swiper-pagination {
      display: none;
    }
  }
}