* {
  padding: 0;
  margin: 0;
}

html,
body {
  height: 100%;
}

body {
  @include font-body;

  margin: 0;
  font-size: 16px;
  line-height: 24px;
  color: $color-text-grey;
  background-color: $color-base-white;
  background-image: url('~images/pattern-1.svg');
  background-repeat: repeat;
  background-blend-mode: luminosity;

  @include sp() {
    font-size: 14px;
    line-height: 21px;
  }
}

// NOTE: Overwrite sanitize.css
h1 {
  margin: 0;
  font-family: $font-family-header;
  font-size: 40px;
  line-height: 1.5;
}
h2 {
  font-family: $font-family-header;
  font-size: 30px;
  line-height: 1.5;
}
h3 {
  font-family: $font-family-header;
  font-size: 22px;
  line-height: 1.5;
}
h4,
h5,
h6 {
  font-family: $font-family-header;
  font-size: 22px;
  line-height: 1.5;
}

ul,
ol,
li {
  list-style: none;
}

a {
  color: inherit;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

em {
  font-style: normal;
}

// Overwrite sanitize.css
html {
  line-height: normal;
}

input,
select {
  word-break: break-all; // To sanitize input's behavior on Safari
  border: none;
  border-radius: 0;
  outline: none;
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -webkit-appearance: none;
  appearance: none;
}

button {
  background-color: transparent;
  border: none;
  outline: none;
  appearance: none;
}

#app-root {
  min-width: $breakpoint-small-pc;
  height: 100%;
  @include sp() {
    min-width: 0;
  }
}

nav li::before {
  display: none;
}
