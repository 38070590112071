.a-contruction-progress-info {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px 26px;
  background: $color-base-white;
  border-radius: 6px;
  box-shadow: 0 7px 20px rgba(0, 0, 0, 0.08);

  @include sp() {
    /* flex-direction: column;
    padding: 20px 34px; */
    padding: 12px;
  }

  &-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 220px;
    padding: 0 26px;
    border-right: 1px solid rgba($color-line-gold, 0.2);

    @include sp() {
      width: 50%;
      padding: 0;
      /* padding: 20px 0;
      border-right: 0;
      border-bottom: 1px solid rgba($color-line-gold, 0.2); */
    }

    &:first-child {
      padding-left: 0;
      @include sp() {
        padding-top: 0;
      }
    }
    &:last-child {
      padding-right: 0;
      border-right: none;

      @include sp() {
        padding-bottom: 0;
        border-bottom: 0;
      }
    }

    &__label {
      font-size: 16px;
      line-height: 24px;
      color: $color-text-grey;
      text-align: center;

      @include sp() {
        font-size: 12px;
        line-height: 18px;
      }
    }

    &__value {
      margin-top: 8px;
      font-family: $font-family-robinson;
      font-size: 24px;
      font-weight: $font-weight-bold;
      line-height: 24px;
      color: $color-text-gold;
      // letter-spacing: 3px;

      @include sp() {
        // margin-top: 10px;
        font-size: 18px;
        line-height: 27px;
      }
    }
  }
}
