.a-project-concept-item {
  display: flex;
  align-items: flex-start;
  justify-content: center;

  @include sp() {
    flex-direction: column;
  }

  &__info {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: flex-start;
  }

  &__icon {
    margin-right: 35px;

    .a-icon {
      width: 70px;
      height: 70px;

      @include sp() {
        width: 48px;
        height: 48px;
      }
    }

    @include sp() {
      margin-right: 12px;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    @include sp() {
      // align-items: center;
      width: 100%;
      padding-top: 5px;
    }
  }

  &__title {
    padding-top: 6px;
    font-family: $font-family-robinson;
    font-size: 18px;
    font-weight: bold;
    line-height: 27px;
    color: $color-text-gold;
    // text-transform: uppercase;

    @include sp() {
      padding-top: 0;
    }
  }

  &__subtitle {
    font-family: $font-family-robinson;
    font-size: 16px;
    line-height: 26px;
  }

  &__description {
    flex: 1;
    max-width: 480px;

    @include sp() {
      margin-top: 12px;
    }
  }
}
