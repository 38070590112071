.m-project-concept {
  .a-project-concept-item {
    position: relative;
    margin-bottom: 100px;

    @include sp() {
      margin-bottom: 80px;
    }

    &::after {
      position: absolute;
      top: 82px; // icon h70px + margin-bottom 12px;
      left: 35px;
      width: 1px;
      height: calc(100% + 6px);
      content: '';
      background: $color-grey-light-1;

      @include sp() {
        // content: none;
        top: auto;
        bottom: -60px;
        left: 50%;
        height: 40px;
        transform: translateX(-50%);
      }
    }

    /* &:last-child {
      margin-bottom: 0;

      &::after {
        content: none;
      }
    } */
  }
}
