.o-modal {
  $root: &;

  position: fixed;
  top: 100%;
  left: 0;
  z-index: $zindex-modal;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.3s ease-out, translateY(128px);
  // transition: transform 0.3s ease-out;
  transform: translateY(128px); // Because Safari can scroll more than actual page height

  &--opened {
    pointer-events: auto;
    opacity: 1;
    transition: opacity 0.3s ease-in-out, translateY(-100%);
    // transition: transform 0.3s ease-in-out;
    transform: translateY(-100%);
  }

  &__window {
    width: 100%;
    height: 100%;
    background-color: $color-base-white;

    #{$root}--no-background & {
      background: transparent;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 56px;
    padding: 0 14px;
    border-bottom: 1px solid $color-line-grey;

    @include tabletOrLess {
      width: 640px;
      margin: 0 auto;
    }
  }

  &__close-button {
    display: flex;
    align-items: center;
    padding: 6px;
    font-family: $font-family-robinson;
    font-size: 14px;
    color: inherit;
    cursor: pointer;

    .a-icon {
      font-size: 32px;
    }
  }

  &__body {
    height: 100%;
    padding-bottom: 48px;
    overflow: hidden;

    #{$root}__header ~ & {
      height: calc(100% - 57px);
    }

    @include tabletOrLess() {
      padding: 0 calc(50% - 320px);
    }
  }
}
