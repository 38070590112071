.m-poi-card-list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  .a-poi-card {
    margin: 22px;
  }
}
