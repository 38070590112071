.a-video-thumbnail {
  $root: &;

  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  @include sp() {
    width: 100%;
  }

  &__button-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;
    transform: translate3d(-50%, -50%, 0);

    img {
      position: relative;
      top: 8px;
    }
  }

  &__container {
    position: relative;
    width: 100%;
    overflow: hidden;
    border-radius: 4px;
  }

  &__image {
    width: 100%;
    max-width: 100%;
    height: auto;
    transition: transform 700ms ease-in-out;
    transform: scale(1);

    #{$root}:hover & {
      transform: scale(1.1);
    }
  }

  &__caption {
    position: absolute;
    bottom: 18px;
    width: 100%;
    font-family: $font-family-robinson;
    font-size: 19px;
    font-weight: bold;
    line-height: 33px;
    color: $color-text-white;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  }
}
