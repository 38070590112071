@use "sass:math";
@import '~video-react/dist/video-react.css';

.bottomheight{
  padding-bottom: 0 !important;
}



.video-react .video-react-big-play-button {
  top: 50%   !important;
  left: 50% !important;
  margin-left: -1.5em !important;
  margin-top: -0.75em !important;
}


.video-react{
  background-color:#807724;
}

.all
{
  background: #807724 url(/images/glance/history/icon.png) 50%;
  
}

.center{
  width: 1200px;
  margin: 0 auto;
  padding-top: 1px;
  padding-bottom: 70px;
  color:#FFF;
}

.center video{
  border-radius: 32px 0;
}

.t-home__project-info-text-video .a-text{
  margin-top: 15px;
}

    


.t-home {
  $root: &;

  position: relative;
  width: 100%;
  height: 100vh;
  padding-top: 20px;
  overflow: hidden;
  transition: padding-top 700ms;

  &--hero {
    // height: 100vh;
    height: 100%;
    // padding-top: 100vh;
    padding-top: 100%;
    // overflow: hidden;
    /* stylelint-disable-next-line value-no-vendor-prefix */
    // padding-top: -webkit-fill-available;
  }

  &--scrollable {
    height: auto;
    overflow: inherit;
  }

  &__hero-banner {
    position: fixed;
    // position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
    // z-index: -1;
    width: 100%;
    height: 100vh;
    /* stylelint-disable-next-line value-no-vendor-prefix */
    // height: -webkit-fill-available;

    #{$root}--safari & {
      /* stylelint-disable-next-line value-no-vendor-prefix */
      height: -webkit-fill-available;
    }

    /* stylelint-disable-next-line value-no-vendor-prefix */
    // max-height: -webkit-fill-available;
    max-height: 100vh;
    overflow: hidden;
    // pointer-events: none;
    transition: max-height 1.5s, z-index 1.5s;

    &--hide {
      z-index: -1;
    }

    &-heading-text {
      @include tablet() {
        span {
          display: block;
        }
      }
    }

    &-carousel {
      pointer-events: none;
    }

    .video-react {
      height: 100vh;
      background-image: url('~images/video-cover.jpg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;

      #{$root}--banner-played & {
        background-color: $color-primary-black;
        background-image: none;
      }

      .video-react-big-play-button {
        display: none;
      }
      .video-react-poster {
        width: 100%;
        height: 100vh;
        background-size: cover;
      }

      &-playing {
        .video-react-poster {
          display: none;
        }
      }
    }

    &--horizontal {
      video {
        top: 50% !important;
        width: 100vw !important;
        height: auto !important;
        transform: translate3d(0, -50%, 0) !important;
      }
    }

    &--vertical {
      video {
        left: 50% !important;
        width: auto !important;
        height: 100vh !important;
        transform: translate3d(-50%, 0, 0) !important;

        #{$root}--retina & {
          @include tabletOrLess() {
            top: 48px !important;
            height: calc(100vh - 48px) !important;
          }
        }
      }
    }

    &--hidden {
      visibility: hidden;
    }

    &-heading {
      position: absolute;
      top: 170px;
      left: 50%;
      z-index: 1;
      width: 100%;
      pointer-events: none;
      transform: translate3d(-50%, 0, 0);
    }

    .o-carousel {
      .swiper-slide {
        overflow: hidden;

        picture {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;
          // transition: transform 5s ease-in-out;
          // transform: scale(1);

          img {
            width: 100%;
            height: auto;
            // border-radius: 30px 0;

            @include sp() {
              width: auto;
              height: 100%;
              
}

            @supports (object-fit: cover) {
              display: block;
              width: 100%;
              max-width: 100%;
              height: 100%;
              object-fit: cover;

              @include sp() {
                display: block;
                width: 100%;
                max-width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }
          }
        }

        &:hover {
          picture {
            // transform: scale(1.1);
          }
        }
      }
    }

    &-scroll-icon-holder {
      position: absolute;
      bottom: 20px;
      left: 50%;
      z-index: 1000;
      cursor: pointer;
      // pointer-events: none;
      transform: translate3d(-50%, 0, 0);
    }

    &-scroll-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 44px;
      height: 44px;
      overflow: hidden;
      background: $color-base-white;
      border-radius: 50%;
    }
  }

  &__waypoint {
    position: absolute;
    top: calc(100vh + 10px);
    /* stylelint-disable-next-line value-no-vendor-prefix, function-calc-no-unspaced-operator */
    // top: calc(-webkit-fill-available + 40px);
    left: 0;
    z-index: 9999;
    width: 100%;
    // border-top: 1px dotted red;
    border-top: 1px dotted transparent;

    &--active {
      top: 80px;
    }
  }

  &__main {
    position: relative;
    z-index: 2;
    // min-height: 100%;
    height: 100%;
    opacity: 1;
    /* stylelint-disable-next-line value-no-vendor-prefix */
    // min-height: -webkit-fill-available;
    // transform: translate3d(0, 100vh, 0);
    /* stylelint-disable-next-line value-no-vendor-prefix */
    // transform: translate3d(0, -webkit-fill-available, 0);
    // padding-top: 83px;
    // transform: translate3d(0, 100vh, 0);
    // margin-top: 100vh;
    /* stylelint-disable-next-line value-no-vendor-prefix */
    // margin-top: -webkit-fill-available;
    transition: padding-top 700ms cubic-bezier(0.645, 0.045, 0.355, 1) 0s;

    @include sp() {
      // padding-top: 48px;
    }

    &--hero {
      height: 0;
      // min-height: 0;
      // padding-top: calc(100vh - 83px);
      padding-top: 0;
      overflow: hidden;
      opacity: 0;
      // transition: padding-top 700ms cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
      transition: opacity 400ms cubic-bezier(0.645, 0.045, 0.355, 1) 0s,
        height 400ms cubic-bezier(0.645, 0.045, 0.355, 1) 0s, padding-top 400ms cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
      // margin-top: 0;
      // transform: translate3d(0, 0, 0);
    }
  }

  &__whole {
    max-width: 100%;
    padding-top: 77px;

    &--no-bg {
      background: none;
    }

    @include tabletOrLess() {
      padding-top: 26px;
    }
  }

  &__head {
    width: 100%;
    max-width: none;
    min-height: 100vh;
    /* stylelint-disable-next-line value-no-vendor-prefix */
    // min-height: -webkit-fill-available;
    padding-right: 24px;
    // padding-bottom: 98px;
    padding-bottom: 160px;
    padding-left: 24px;
    background: rgba(255, 255, 255, 0.6);
    // background-image: url('~images/home-bg-blur.jpg');
    // background-repeat: no-repeat;
    // background-size: cover;
    backdrop-filter: blur(40px) brightness(2);

    @include sp() {
      min-height: auto;
      padding-right: 16px;
      padding-bottom: 32px;
      padding-left: 16px;

      .a-heading--welcome .a-heading__title {
        padding-right: 16px;
        padding-left: 16px;
      }
    }

    &--hide {
      margin-bottom: 100px;
    }

    .a-heading {
      margin-bottom: 32px;
    }

    &-text {
      max-width: 560px;
      margin-right: auto;
      margin-bottom: 16px;
      margin-left: auto;
      text-align: center;

      &--line-1 {
        max-width: 800px;

        #{$root}--th & {
          max-width: 670px;
        }
        @include tablet() {
          max-width: 552px;

          #{$root}--th & {
            max-width: 476px;
          }
        }

        @include sp() {
          max-width: 310px;

          #{$root}--th & {
            max-width: 320px;
          }
        }
      }

      &--line-2 {
        max-width: 636px;

        #{$root}--th & {
          max-width: 600px;
        }
        @include tablet() {
          max-width: 540px;

          #{$root}--th & {
            max-width: 508px;
          }
        }
        @include sp() {
          max-width: 332px;

          #{$root}--th & {
            max-width: 332px;
          }
        }
      }
    }
  }

  &__shortcuts {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1015px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 105px;
    margin-right: auto;
    margin-left: auto;
    border-top: 1px solid $color-line-ivory;
    border-bottom: 1px solid $color-line-ivory;

    @include sp() {
      margin-top: 24px;
      border: 0;
    }

    .a-shortcut-item {
      width: 33.33%;

      .a-shortcut-item__title {
        letter-spacing: 3px;

        #{$root}--th & {
          letter-spacing: 0;
        }
      }

      @include sp() {
        width: 100%;
        max-width: 328px;
      }
    }
  }

  &__inner-content {
    width: 100%;

    &--force-bg {
      background-color: $color-base-white;
      background-image: url('~images/pattern.png');
      background-repeat: repeat;
      background-attachment: fixed;
      background-position: center;
    }
  }

  &__project-info {
    &-waypoint {
      position: relative;
      top: 183px;
      // border-top: 1px dotted red;
      border-top: 1px dotted transparent;
    }

    &-content {
      .a-heading {
        &__title {
          line-height: 40px;
        }
        .a-heading__subtitle-top {
          line-height: 30px;
        }
      }
    }

    &-text {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      padding-right: 24px;
      padding-left: 24px;
      margin-top: 70px;

      @include sp() {
        flex-direction: column;
        margin-top: 26px;
      }

      &-content {
        width: 600px;

        @include sp() {
          width: 100%;
        }

        .a-text {
          margin-bottom: 30px;

          #{$root}--th & {
            max-width: 460px;

            @include tablet() {
              max-width: 306px;
            }
          }
        }
      }

      &-video {
        width: 496px;
        margin-left: 24px;

        @include sp() {
          width: 100%;
          margin-left: 0;
        }
      }
    }
  }

  &__project-concept {
    padding-right: 24px;
    padding-bottom: 0;
    padding-left: 24px;
   margin-top: 0 !important;

    @include sp() {
      padding-bottom: 0;
      margin-top: 32px;
    }

    .m-project-concept {
      & > div {
        &:nth-child(1) {
          .a-project-concept-item {
            &__description {
              width: 500px;

              #{$root}--th & {
                width: 480px;
              }

              @include tablet() {
                width: 360px;
              }

              @include sp() {
                width: auto;

                #{$root}--th & {
                  width: auto;
                }
              }
              span {
                display: block;
                max-width: 465px;

                #{$root}--th & {
                  max-width: 470px;
                }

                @include tablet() {
                  display: block;
                  max-width: 340px;

                  #{$root}--th & {
                    max-width: 324px;
                  }
                }

                @include sp() {
                  display: block;
                  max-width: 310px;

                  #{$root}--th & {
                    max-width: 310px;
                  }
                }
              }
            }
          }
        }
        &:nth-child(2) {
          .a-project-concept-item {
            &__description {
              width: 480px;

              @include tablet() {
                width: 360px;
              }

              @include sp() {
                width: auto;
              }
              span {
                display: block;
                max-width: 458px;

                #{$root}--th & {
                  max-width: 436px;
                }

                @include tablet() {
                  display: block;
                  max-width: 352px;

                  #{$root}--th & {
                    max-width: 350px;
                  }
                }

                @include sp() {
                  display: block;
                  max-width: 310px;
                }
              }
            }
          }
        }
        &:last-child {
          .a-project-concept-item {
            margin-bottom: 0;

            &__description {
              width: 480px;

              @include tablet() {
                width: 360px;
              }
              @include tabletOrLess() {
                width: auto;
              }
              span {
                display: block;
                max-width: 465px;

                #{$root}--th & {
                  display: block;
                  max-width: 448px;
                }

                @include tablet() {
                  display: block;
                  max-width: 328px;

                  #{$root}--th & {
                    max-width: 342px;
                  }
                }

                @include sp() {
                  display: block;
                  max-width: 310px;

                  #{$root}--th & {
                    max-width: 304px;
                  }
                }
              }
            }

            &::after {
              content: none;
            }
          }
        }
      }
    }
  }

  &__project-progress {
    width: 100%;
    max-width: none;
    padding-bottom: 124px;

    @include sp() {
      padding-bottom: 32px;
    }
  }

  &__gallery {
    width: 100%;
    max-width: none;
    padding-bottom: 124px;

    @include sp() {
      padding-bottom: 32px;
    }

    &-lightbox-content {
      width: 0;
      height: 0;
      overflow: hidden;
    }

    /* stylelint-disable-next-line no-descending-specificity */
    .swiper-slide {
      @media (min-width: 641px) and (max-width: 992px) {
        max-width: 70%;
      }
      @include sp() {
        // padding-right: 16px;
        // padding-left: 16px;
        max-width: 80%;
      }
      .a-image {
        .a-image__image-holder {
          border-radius: 4px;

          picture {
            transition: transform 1.2s ease-in-out;
            transform: scale(1.2);

            @include tabletOrLess() {
              transform: scale(1.2);
            }

            img {
              // position: absolute;
              // transition: transform 250ms;
              width: 100%;
              transform: scale(1.2);

              @include tabletOrLess() {
                max-width: 100%;
                transform: scale(1.2);
              }
            }
          }
        }
      }

      /* &-active {
        @include sp() {

          transform: scale(1);
        }
      } */

      /* stylelint-disable-next-line no-descending-specificity */
      &:hover {
        cursor: pointer;
        .a-image {
          .a-image__image-holder {
            picture {
              transition: transform 0.5s;
              transform: scale(1.3);
              -webkit-transition: transform 0.5s;
              -moz-transition: transform 0.5s;
              -ms-transition: transform 0.5s;
              -o-transition: transform 0.5s;
}
          }
        }
      }
    }
  }

  &__news {
    width: 100%;
    max-width: none;
    // min-height: 100vh;
    margin-bottom: 124px;

    &-waypoint {
      position: relative;
      top: 150px;
      left: 0;
    }

    @include sp() {
      margin-bottom: 32px;
    }

    &-title {
      margin-bottom: 48px;

      @include sp() {
        margin-top: 6px;
        margin-bottom: 26px;
      }
    }

    &-carousel {
      .o-carousel__container {
        padding-top: 28px;

        @include sp() {
          padding-top: 0;
        }
      }
    }
  }

  &__getting-here {
    width: 100%;
    max-width: none;

    &-waypoint {
      position: relative;
      top: 150px;
      // border-top: 1px dotted red;
    }

    &-heading {
      margin-bottom: 75px;

      @include sp() {
        margin-bottom: 32px;
      }
    }

    &-content {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding-right: 24px;

      @include sp() {
        flex-direction: column;
        text-align: center;
      }

      &-image {
        // width: 780px;
        width: 100%;

        @include sp() {
          padding-right: 16px;
          margin-bottom: 24px;
        }
      }
      &-text {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        width: 100%;
        margin-left: 54px;

        @include sp() {
          padding-right: 16px;
          padding-left: 16px;
          margin-left: 0;
        }

        .a-text {
          max-width: 450px;

          @include sp() {
            margin-right: auto;
            margin-left: auto;
          }

          #{$root}--th & {
            max-width: 424px;

            @include tablet() {
              max-width: 336px;
            }

            @include sp() {
              max-width: 300px;
            }
          }
        }
      }
      &-view {
        margin-top: 24px;

        @include sp() {
          margin-right: auto;
          margin-left: auto;
        }
      }
    }

    &-tabs {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      min-height: 560px;
      margin-top: 128px;

      @include tablet() {
        min-height: 0;
        margin-bottom: 64px;
      }

      &-content {
        max-width: 100%;
      }

      @include sp() {
        flex-direction: column;
        justify-content: flex-start;
        min-height: 0;
        margin-top: 80px;
        margin-bottom: 48px;
      }

      .m-tabs__content {
        margin-top: 64px;

        @include sp() {
          margin-top: 32px;

          .a-text {
            font-size: 14px;
            line-height: 23px;
          }
        }
      }
    }
  }

  &__map {
    width: 100%;
    max-width: none;

    @include sp() {
      padding-bottom: 64px;
    }
  }

  &__back-to-top {
    position: fixed;
    right: 0;
    bottom: 0;
    z-index: 2;

    &--stick {
      position: absolute;
      bottom: 340px;

      @include sp() {
        bottom: 240px;
      }
    }
  }

  &__video-modal {
    display: flex;
    align-content: center;
    justify-content: center;
    height: 100vh;

    &-overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.7);
    }

    &-player {
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 1;
      width: 100%;
      max-width: 1015px;
      height: auto;
      max-height: 620px;
      font-family: $font-family-robinson;
      transform: translate3d(-50%, -50%, 0);

      .video-react {
        .video-react-time-control {
          font-family: $font-family-robinson;
        }
      }
    }
  }

  &__subscribe-box {
    position: relative;
    width: 100%;
  }
}

// 插件react-compare-slider
.p-about-glance__now-2022 {
  position: relative;
  overflow: hidden;

  .a-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .a-heading {
    position: relative;
    text-align: left;

    &::after {
      position: absolute;
      top: 4px;
      left: -67px;
      display: inline-block;
      width: 32px;
      height: 16px;
      content: url('~images/home/gallery/timeline-diamond.svg');

      @include tablet {
        left: -61px;
      }

      @include pc {
        top: 14px;
        left: 131px;
      }
    }

    @include pc {
      margin-bottom: $spacing-6;
    }

    @include tabletOrLess {
      display: block;
      margin-right: auto;
      margin-bottom: 32px;
      margin-left: 86px;
    }

    span {
      color: $color-base-blue;
      font-size: 24px;
      @include tabletOrMore {
        font-size: 32px;
      }
    }
  }

  &__compare-slider>div {
    @include round-corner(20px);
  }

  &__handler {
    position: relative;
    top: calc(50% - 12px);
    display: flex;
    padding: 6px 0;
    background-color: $color-primary-gold;
    @include round-corner(8px);

    @include pc {
      top: calc(50% - 24px);
      padding: 12px 0;
    }

    .a-icon {
      font-size: 12px;

      @include pc {
        font-size: 24px;
      }
    }
  }

  &__text {
    margin-top: 32px;
    color: $color-text-dark-grey;

    @include tabletOrLess {
      padding-left: 86px;
    }

    @include pc {
      margin-top: 48px;
      text-align: center;
    }
  }
}
.t-title{
  font-size: 32px;
  text-align: center;
  color: #288cff;
  max-width: 1125px;
  margin: 80px auto 40px;
}







