.o-whole {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: $color-base-white;
  background-image: url('~images/pattern.png');
  background-repeat: repeat;
  background-attachment: fixed;
  background-position: center;
}
