.t-venue-floor {
  $root: &;

  width: 100%;

  .a-heading {
    .a-heading__title {
      letter-spacing: 8px;
    }
  }

  &__selector {
    margin-top: 32px;
  }

  &__tabs {
    // max-width: 912px;
    max-width: 1224px;
    margin-top: 32px;
  }

  &__description {
    max-width: 630px;
    margin-top: 64px;
    margin-right: auto;
    margin-left: auto;
    text-align: center;

    #{$root}--1 & {
      max-width: 650px;
    }

    #{$root}--G & {
      max-width: 606px;
    }

    @include sp() {
      margin-top: 32px;
    }
  }

  &__info {
    margin-top: 64px;

    @include sp() {
      margin-top: 16px;
    }

    &-content {
      width: 100%;
      height: auto;
      overflow: hidden;
    }

    .m-tabs {
      max-width: 640px;
      margin: 0 auto;
    }

    &--floor {
      max-width: 1224px;

      .m-tabs__tab-header {
        max-width: 704px;
        margin-right: auto;
        margin-left: auto;
      }

      &-1 {
        .m-tabs__tab-header {
          max-width: none;
        }
      }

      /* .m-tabs__tab {
        .a-tab-item {
          span {
            display: block;
            height: 40px;
            padding: 0 10px;
          }
        }
      } */
    }

    &-floor {
      display: none;
      margin-top: 72px;

      @include sp() {
        margin-top: 32px;
      }

      &--active {
        display: block;
      }

      &-image {
        position: relative;
        width: 100%;
        max-width: 100%;
        text-align: center;

        img {
          max-width: 100%;
          height: auto;
        }

        &-img {
          position: relative;
          z-index: 1;
        }

        &-shadow {
          position: absolute;
          bottom: 0;
          left: 50%;
          z-index: 0;
          transform: translate3d(-50%, 20%, 0);
        }
      }

      &-legends {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        max-width: 860px;
        margin-top: 100px;
        margin-right: auto;
        margin-left: auto;

        @include sp() {
          margin-top: 32px;
        }

        &-item {
          $item: &;

          display: flex;
          align-content: center;
          justify-content: flex-start;
          width: 20%;
          margin-bottom: 8px;
          overflow: hidden;
          font-size: 14px;
          line-height: 24px;
          text-overflow: ellipsis;
          white-space: nowrap;

          @media (min-width: 641px) and (max-width: 992px) {
            width: 25%;
          }

          @include sp() {
            width: 50%;
            font-size: 12px;
          }

          &-color {
            display: inline-block;
            width: 24px;
            height: 24px;
            margin-right: 10px;
            border: 1px solid transparent;

            #{$item}--border-dashed & {
              border-color: $color-line-grey;
              border-style: dashed;
            }
          }
        }
      }

      &-data {
        margin-top: 104px;
        margin-bottom: 85px;

        @include sp() {
          margin-top: 32px;
        }

        &-description {
          // color: $color-text-black;

          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-right: 16px;
          padding-left: 16px;
          font-size: 16px;
          line-height: 26px;

          @include sp() {
            flex-direction: column;
            padding: 0;
            font-size: 14px;
          }

          &-item {
            margin-bottom: 16px;
          }
        }
      }
    }

    &--layout {
      max-width: 1224px;
    }

    &-layout {
      &-select {
        width: 288px;
        margin-top: 26px;
        margin-right: auto;
        margin-left: auto;
      }
      &-data-table {
        margin-top: 48px;

        @include tabletOrLess() {
          margin-right: -24px;
          margin-left: -24px;
        }
      }
      &-description {
        padding-right: 15px;
        padding-left: 15px;
        margin-top: 64px;
        margin-bottom: 64px;
        font-size: 18px;
        line-height: 27px;
        color: $color-text-black;

        @include sp() {
          font-size: 16px;
          line-height: 24px;
        }
      }
    }

    &--gallery {
      padding-bottom: 128px;

      @include sp() {
        padding-bottom: 85px;
      }
    }

    &--360 {
      max-width: 1224px;
      padding-bottom: 128px;

      @include sp() {
        padding-bottom: 85px;
      }
    }
  }

  &__360-modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: $zindex-modal;
    width: 100%;
    height: 100%;
    background: $color-base-white;

    &-close {
      position: fixed;
      top: 40px;
      right: 40px;
      z-index: 9999;

      @include sp() {
        top: 20px;
        right: 20px;
      }

      .a-button {
        background: rgba($color-base-white, 0.5);

        &:hover {
          background: rgba($color-base-white, 0.9);
        }
      }
    }

    .a-canvas {
      // display: none;
    }
  }
}
