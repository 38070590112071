.a-info-card-item {
  $root: &;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 310px;
  padding: 36px 24px 24px;
  cursor: pointer;
  background: $color-base-white;
  border: 4px solid transparent;
  border-radius: 4px;
  box-shadow: 0 7px 20px rgba(0, 0, 0, 0.08);

  @include sp() {
    width: 242px;
  }

  &:hover {
    border-color: $color-line-gold;
  }

  &__image {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 195px;
    overflow: hidden;
    background: $color-grey-light-3;
    border-radius: 4px;

    @include sp() {
      height: 146px;
    }

    picture {
      transition: transform 1.2s ease-in-out;
      transform: scale(1);

      #{$root}:hover & {
        transform: scale(1.1);
      }
    }

    img {
      height: 195px;
      @include sp() {
        height: 146px;
      }
    }
  }

  &__title {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80px;
    margin-top: 16px;
    font-family: $font-family-robinson;
    font-size: 22px;
    font-weight: bold;
    line-height: 33px;
    color: $color-text-gold;
    text-align: center;
    text-transform: uppercase;
    // letter-spacing: 2px;

    @include sp() {
      height: 72px;
      font-size: 18px;
    }
  }

  &__description {
    height: 55px;
    padding-top: 9px;
    margin-top: 10px;
    font-weight: 600;
    text-align: center;
    border-top: 1px solid rgba($color-line-gold, 0.2);

    @include sp() {
      height: 40px;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
    }

    &-text {
      line-height: 24px;

      @include sp() {
        line-height: 18px;
      }
    }
  }
}
