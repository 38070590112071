$spacing-unit: 8px;

$spacing-1: $spacing-unit;
$spacing-2: $spacing-unit * 2;
$spacing-3: $spacing-unit * 3;
$spacing-4: $spacing-unit * 4;
$spacing-5: $spacing-unit * 5;
$spacing-6: $spacing-unit * 6;
$spacing-7: $spacing-unit * 7;
$spacing-8: $spacing-unit * 8;
$spacing-9: $spacing-unit * 9;
$spacing-10: $spacing-unit * 10;
$spacing-11: $spacing-unit * 11;
$spacing-12: $spacing-unit * 12;
$spacing-13: $spacing-unit * 13;
$spacing-14: $spacing-unit * 14;
$spacing-15: $spacing-unit * 15;
$spacing-16: $spacing-unit * 16;
$spacing-17: $spacing-unit * 17;
$spacing-18: $spacing-unit * 18;
$spacing-19: $spacing-unit * 19;
$spacing-20: $spacing-unit * 20;
$spacing-25: $spacing-unit * 25;
