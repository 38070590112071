/**
 * Colors
 *
 * * `primary` and `secondary` ==> For UI, decoration (exclude for graph)
 * * `text` ==> For text
 * * `base` ==> For Background
 * * `shadow` ==> For Box Shadow
 * * `line` ==> For border (e.g. hr, table)
 * * `graph` ==> For graph
 *
 * NOTE: Names (except for "primary") are from `yarn utils:get-color-name`
 */

$color-primary-gold: #827800;
$color-primary-ivory: #d2d2c8;
$color-primary-blue: #288cff;
$color-primary-black: #000000;
$color-primary-white: #ffffff;
$color-primary-error: #e04f39;
$color-primary-grey-dark-3: #bbb8ab;

$color-grey-dark-1: #42403b;
$color-grey-dark-2: #848274;
$color-grey-dark-3: #bbb8ab;
$color-grey-light-1: #d4d2ca;
$color-grey-light-2: #e5e3de;
$color-grey-light-3: #f1efe9;
$color-grey-light-4: #e7e7e7;
$color-grey-light-5: #f9f9f6;

$color-dark-gold: #302d06;
$color-light-gold: #cdc999;

// Text
$color-text-black: $color-primary-black;
$color-text-gold: $color-primary-gold;
$color-text-white: $color-primary-white;
$color-text-grey: $color-grey-dark-2;
$color-text-blue: $color-primary-blue;
$color-text-ivory: $color-primary-ivory;
$color-text-dark-grey: #51575a;

// Line / Border
$color-line-white: $color-primary-white;
$color-line-black: $color-primary-black;
$color-line-gold: $color-primary-gold;
$color-line-ivory: $color-primary-ivory;
$color-line-grey: $color-grey-dark-2;

// background
$color-base-white: $color-primary-white;
$color-base-gold: $color-primary-gold;
$color-base-ivory: $color-primary-ivory;
$color-base-blue: $color-primary-blue;
$color-base-gold-2: #5c5502;
$color-base-gold-3: #706804;
