.a-location-map-list-item {
  $root: &;

  &__button {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    pointer-events: none;

    #{$root}--action & {
      pointer-events: inherit;
      cursor: pointer;
    }
  }

  &__icon {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 54px;
    height: 54px;

    &-bg {
      #{$root}--logo & {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        font-size: 14px;
        background: $color-base-white;
        border-radius: 50%;
        box-shadow: 0 2px 10px rgba(67, 67, 67, 0.4);

        .a-logo {
          &--mrt-circle {
            font-size: 18px;
          }
        }
      }
    }

    &-text {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      margin-top: -2px;
      font-family: $font-family-robinson;
      font-size: 14px;
      font-weight: bold;
      color: $color-primary-blue;
      transform: translate(-50%, -50%);
    }
  }

  &__text {
    padding-left: 4px;
    font-family: $font-family-bai-jamjuree;
    font-size: 14px;

    #{$root}--logo & {
      font-weight: 600;
    }
  }
}
