
.t-venue {
  $root: &;

  .a-heading {
    .a-heading__title {
      letter-spacing: 8px;
    }
  }

  &__description {
    max-width: 704px;
    margin-top: 32px;
    margin-right: auto;
    margin-left: auto;
    text-align: center;

      

    #{$root}--th & {
      max-width: 690px;
    }

    @include sp() {
      max-width: 318px;

      #{$root}--th & {
        max-width: 300px;
      }
    }
  }

  &__floor-selectors {
    padding-top: 96px;
    padding-bottom: 0;

    @include tablet() {
      padding-bottom: 180px;
    }

    @include sp() {
      padding-top: 40px;
      padding-bottom: 320px;
    }
  }
  .event{
    display: flex;
    justify-content: center;
    margin-bottom: 2em;
  }
  .promotion{
    color: #827800;
    border-color: #827800;
    width: 100%;
    max-width: 288px;
    height: 60px;
    font-family: "Robinson", "Helvetica", "Arial", "KiriMaya", sans-serif;
    font-size: 18px;
    line-height: 47px;
    display: block;
    background: #ffffff;
    border: 4px solid #827800;
    border-radius: 6px;
    text-decoration: none;
  }
}
