.a-view-more {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: $color-text-gold;

  &:hover {
    text-decoration: underline;
  }

  &--blue {
    color: $color-text-blue;
  }

  &__icon {
    font-size: 24px;
  }
}
