.m-floor-selector-full {
  $root: &;

  $pc-margin: 160px;
  $tablet-margin: 160px;
  $sp-margin: 100px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 1550px;
  // margin-bottom: -960px;
  // transition: margin-bottom 1s;
  // transition: height 700ms;
  // overflow: hidden;

  /* &--active {
    // margin-bottom: -800px;
    height: 1600px;
  } */

  /* @include sp() {
    height: 610px;
    overflow: scroll;
  } */

  @include sp() {
    padding-top: 0;
    margin-bottom: -600px;
  }

  &__floor {
    position: relative;
    // border: 1px solid red;
    // margin-top: -#{$pc-margin};
    // transition: margin-top 400ms, margin-bottom 400ms;

    @include tablet() {
      // margin-top: -#{$tablet-margin};
    }

    @include sp() {
      // margin-top: -#{$sp-margin};
    }

    &:first-child {
      // margin-top: 0;
    }

    &--active {
      margin: 0;
      // margin-bottom: #{$pc-margin + 40};

      @include tablet() {
        // margin-bottom: #{$tablet-margin};
      }

      @include sp() {
        padding-top: 100px;
        padding-bottom: 100px;
        // margin-bottom: #{$sp-margin};
      }
    }

    &:last-child {
      &#{$root}__floor--active {
        // margin-bottom: 0;
      }
    }
  }
}
