.a-text-field {
  $root: &;

  position: relative;
  display: inline-flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  padding-bottom: 24px;

  &__label {
    position: relative;
    font-family: $font-family-robinson;
    font-size: 16px;
    font-weight: bold;
    line-height: 16px;
    color: $color-grey-dark-3;
    opacity: 1;
    transition: opacity 400ms;

    &--blank {
      opacity: 0;
      transition: opacity 400ms;
    }

    #{$root}--invalid & {
      color: $color-primary-error;
    }
  }

  &__input {
    box-sizing: border-box;
    display: block;
    width: 100%;
    padding: 10px 0;
    font-family: $font-family-bai-jamjuree;
    font-size: 18px;
    color: $color-text-black;
    background: transparent;
    border-bottom: 1px solid $color-line-grey;

    &::placeholder {
      font-family: $font-family-robinson;
      font-weight: bold;
      color: $color-grey-light-2;
      opacity: 1;

      #{$root}--invalid & {
        color: $color-primary-error;
      }
    }

    &:focus {
      border-color: $color-line-gold;
    }

    &:disabled {
      color: $color-text-grey;
      pointer-events: none;
      background-color: $color-grey-light-4;
      opacity: 1;
    }

    &[type='number'] {
      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        appearance: none;
      }
    }

    #{$root}--alphanumeric & {
      &::placeholder {
        line-height: 20px;
      }
    }

    #{$root}--invalid & {
      color: $color-primary-error;
      border-color: $color-primary-error;
    }
  }

  .a-error-balloon {
    position: absolute;
    top: -8px;
    right: 0;
    max-width: 70%;
    transform: translateY(-100%);
  }

  &__error {
    position: absolute;
    top: 100%;
    left: 0;
    font-size: 12px;
    color: $color-primary-error;
    transform: translateY(-24px);
  }
}
