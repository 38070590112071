.o-venue-info {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;

  .m-venue-info-block {
    @include sp() {
      width: 100%;
      padding: 20px 0;
      border-top: 1px solid $color-grey-light-1;
    }
  }

  &__top {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 40px 0;
    border-top: 1px solid $color-grey-light-1;

    @include sp() {
      flex-direction: column;
      padding: 0;
      border-top: 0;
    }
  }

  &__bottom {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 40px 0;
    border-top: 1px solid $color-grey-light-1;
    @include sp() {
      flex-direction: column;
      padding: 0;
      border-top: 0;
    }
  }
}
