.o-contact-info {
  display: grid;
  grid-template-columns: repeat(2, calc(50% - 16px));
  column-gap: 32px;
  border-top: 1px solid rgba($color-line-gold, 0.2);

  @include sp() {
    grid-template-columns: 1fr;
    column-gap: 0;
  }

  .m-contact-info-item {
    flex: 1;
    padding-top: 48px;
    padding-bottom: 48px;
    border-bottom: 1px solid rgba($color-line-gold, 0.2);
  }
}
