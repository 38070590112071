.m-subscribe-box {
  // display: flex;
  // flex-direction: column;
  // align-items: center;
  // justify-content: center;
  height: 100px;
  padding-right: 16px;
  padding-left: 16px;
  // background-image: url('~images/subscribe-box-bg.jpg');
  background: #807724 url('/images/glance/history/icon.png') 50%;
  // background-repeat: no-repeat;
  // background-position: bottom;
  // background-size: cover;


  @include sp() {
    // height: 240px;
    padding-top: 45px;
    background-size: auto 100%;
  }

  &__title {
    display: none;
    font-size: 24px;
    color: $color-text-white;

    @include sp() {
      font-size: 18px;
      line-height: 27px;
    }
  }

  &__subtitle {
    display: none;
    max-width: 460px;
    margin-top: 10px;
    color: $color-text-white;

    @include sp() {
      width: 100%;
      padding-right: 10px;
      padding-left: 10px;
      font-size: 14px;
    }
  }

  &__form {
    position: relative;
    display: none;
    width: 100%;
    max-width: 380px;
    min-height: 84px;
    margin-top: 36px;

    @include sp() {
      width: 100%;
      max-width: 328px;
      margin-top: 6px;
    }

    &-loading {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      width: 100%;
      height: 100%;

      &-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 30px;
        transform: translate(-50%, -50%);
      }
    }
  }
}
