.a-button {
  $root: &;

  position: relative;
  display: inline-flex;
  padding: 10px 23px 9px;
  overflow: hidden;
  font-family: $font-family-robinson;
  font-size: 18px;
  font-weight: $font-weight-bold;
  line-height: 27px;
  color: $color-text-gold;
  cursor: pointer;
  // background: $color-base-white;
  border: 1px solid $color-line-gold;
  transition: background 400ms;

  &--icon {
    padding: 10px;
  }

  &__label {
    position: relative;
    z-index: 1;
  }

  @keyframes ripple {
    to {
      opacity: 0;
      transform: scale(4);
    }
  }

  &__ripple {
    position: absolute;
    z-index: 0;
    background-color: $color-base-gold-2;
    border-radius: 50%;
    opacity: 0.3;
    transform: scale(0);
    animation: ripple 600ms linear;
  }

  &:hover {
    text-decoration: none;
    background: rgba($color-base-gold, 0.2);
  }

  &:disabled,
  &:disabled:hover,
  &--disabled,
  &--disabled:hover {
    color: $color-base-ivory;
    cursor: not-allowed;
    background: $color-grey-light-3;
    border-color: $color-line-ivory;
  }

  &--large {
    padding: 16px 50px 15px;
  }

  &--small {
    padding: 8px 23px 7px;
  }

  &--primary {
    color: $color-text-white;
    background: $color-base-gold;

    &:hover {
      background: $color-base-gold-3;
    }

    &:active {
      background: $color-base-gold;
    }

    &#{$root}--large {
      #{$root}__ripple {
        animation: ripple 800ms linear;
      }
    }
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;

    .a-icon {
      position: relative;
      top: -2px;
      font-size: 16px;
    }

    #{$root}--icon & {
      margin-right: 0;

      .a-icon {
        top: 0;
      }
    }
  }
}
