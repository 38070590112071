.a-floor-selector {
  $root: &;

  display: flex;
  align-items: center;
  justify-content: center;

  &__label {
    min-width: 48px;
    font-family: $font-family-robinson;
    font-weight: bold;
    text-align: right;
    text-transform: uppercase;
  }

  &__input {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__indicator {
    min-width: 48px;

    .a-floor-indicator {
      padding-top: 0;
    }
  }

  &__button {
    position: relative;
    z-index: 1;
    cursor: pointer;

    .a-icon {
      font-size: 40px;

      @include sp() {
        font-size: 32px;
      }
    }

    &:disabled {
      cursor: not-allowed;
      .a-icon {
        color: $color-text-grey;
        opacity: 0.5;
      }
    }
  }

  &__floor {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 150px;
    overflow: hidden;

    &-ghost {
      --ghost-top: 0;

      position: absolute;
      // top: var(--ghost-top, 0);
      top: 0;
      left: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      width: 150px;
      // transition: transform 400ms ease-in-out, color 400ms;
      // transition: top 400ms ease-in-out, color 400ms;
      transform: translateY(var(--ghost-top, 0));
    }

    &-item,
    &-ghost-item {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 150px;
      height: 60px;
      text-align: center;

      &-label {
        padding-top: 8px;
        font-family: $font-family-robinson;
        font-size: 60px;
        font-weight: bold;
        line-height: 60px;
        color: $color-text-gold;

        @include sp() {
          font-size: 48px;
          line-height: 48px;
        }
      }
    }

    &-item {
      position: relative;
    }

    &--going-up {
      // hide current floor
      #{$root}__floor-item {
        opacity: 0;
      }
    }

    &--going-down {
      // hide current floor
      #{$root}__floor-item {
        opacity: 0;
      }
    }
  }
}
