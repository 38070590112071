.t-hotel-surrounding-detail {
  $root: &;

  position: relative;

  &__back-button {
    position: absolute;
    top: 40px;
    left: 58px;
    z-index: 1;

    @include tabletOrLess() {
      // display: none;
      top: 10px;
      left: 16px;
    }
  }

  &__content {
    max-width: 808px;
    margin-right: auto;
    margin-left: auto;
  }

  &__heading {
    max-width: 740px;

    .a-heading__title {
      font-size: 32px;
      text-transform: none;
      letter-spacing: 0;
      @include sp() {
        padding-right: 0;
        padding-left: 0;
        font-size: 28px;
      }
    }
  }

  &__direction {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 32px;

    .a-button {
      justify-content: center;
      width: 212px;
      margin-top: 24px;
    }
  }

  &__excerpt {
    margin-top: 32px;

    &-main {
      font-family: $font-family-bai-jamjuree;
      font-size: 16px;
      font-weight: normal;
      line-height: 26px;
      color: $color-text-black;
    }

    &-subtitle {
      margin-top: 32px;
      font-size: 14px;

      @include tabletOrLess() {
        margin-right: auto;
        margin-left: auto;
      }

      // HOTELS
      #{$root}--modena-by-fraser-bangkok#{$root}--th & {
        max-width: 772px;

        @include tablet() {
          max-width: 678px;
        }

        @include sp() {
          max-width: 305px;
        }
      }
      #{$root}--bangkok-marriott-marquis-queens-park#{$root}--th & {
        max-width: 800px;

        @include tablet() {
          max-width: 700px;
        }

        @include sp() {
          max-width: 316px;
        }
      }
      #{$root}--the-athenee-hotel#{$root}--th & {
        max-width: 776px;

        @include tablet() {
          max-width: 678px;
        }

        @include sp() {
          max-width: 312px;
        }
      }

      #{$root}--the-okura-prestige-bangkok#{$root}--th & {
        max-width: 780px;

        @include tablet() {
          max-width: 698px;
        }

        @include sp() {
          max-width: 302px;
        }
      }

      #{$root}--w-bangkok#{$root}--th & {
        max-width: 772px;

        @include tablet() {
          max-width: 706px;
        }

        @include sp() {
          max-width: 295px;
        }
      }

      // SURROUNDINGS
      #{$root}--the-parq#{$root}--th & {
        max-width: 800px;

        @include tablet() {
          max-width: 692px;
        }

        @include sp() {
          max-width: 312px;
        }
      }
      #{$root}--benjakitti-forest-park#{$root}--th & {
        max-width: 700px;

        @include tablet() {
          max-width: 672px;
        }

        @include sp() {
          max-width: 323px;
        }
      }
      #{$root}--medpark-hospital#{$root}--th & {
        max-width: 768px;

        @include tablet() {
          max-width: 670px;
        }

        @include sp() {
          max-width: 316px;
        }
      }
      #{$root}--one-bangkok#{$root}--th & {
        max-width: 778px;

        @include tablet() {
          max-width: 678px;
        }

        @include sp() {
          max-width: 288px;
        }
      }
      #{$root}--terminal21-asok#{$root}--th & {
        max-width: 754px;

        @include tablet() {
          max-width: 670px;
        }

        @include sp() {
          max-width: 312px;
          font-size: 13px;
        }
      }
      #{$root}--siam-paragon#{$root}--th & {
        max-width: 750px;

        @include tablet() {
          max-width: 650px;
        }

        @include sp() {
          max-width: 324px;
        }
      }
      #{$root}--bangkok-art-and-culture-centre#{$root}--th & {
        max-width: 752px;

        @include tablet() {
          max-width: 664px;
        }

        @include sp() {
          max-width: 298px;
        }
      }
      #{$root}--samyan-mitrtown#{$root}--th & {
        max-width: 790px;

        @include tablet() {
          max-width: 700px;
        }

        @include sp() {
          max-width: 308px;
        }
      }
      #{$root}--bangkok-chinatown#{$root}--th & {
        max-width: 794px;

        @include tablet() {
          max-width: 692px;
        }

        @include sp() {
          max-width: 299px;
        }
      }
    }
  }

  &__locations {
    margin-top: 32px;

    .a-location-info-item--address {
      max-width: 420px;
    }
  }

  &__images {
    padding-bottom: 115px;
    margin-top: 40px;

    @include sp() {
      padding-bottom: 85px;
    }

    &-item {
      margin-bottom: 32px;
      cursor: pointer;
    }
  }
}
