.a-select-field {
  $root: &;

  position: relative;

  &__label {
    position: relative;
    font-family: $font-family-robinson;
    font-weight: bold;
    color: $color-grey-dark-3;
    opacity: 1;
    transition: opacity 400ms;

    /* &--blank {
      opacity: 0;
      transition: opacity 400ms;
    } */

    #{$root}--invalid & {
      color: $color-primary-error;
    }
  }

  &__select-container {
    position: relative;
  }

  &__select {
    position: relative;
    width: 100%;
    height: 44px;
    padding-right: 35px;
    padding-left: 0;
    font-family: $font-family-bai-jamjuree;
    font-size: 16px;
    line-height: 16px;
    line-height: 44px;
    color: $color-text-black;
    text-overflow: ellipsis;
    background: transparent;
    border-bottom: 1px solid $color-line-grey;
    outline: 0;
    appearance: none;

    #{$root}--invalid & {
      color: $color-primary-error;
      border-color: $color-primary-error;
    }

    &:disabled {
      color: $color-text-grey;
      background-color: $color-grey-light-4;
    }

    &--blank {
      font-family: $font-family-robinson;
      font-size: 22px;
      font-weight: bold;
      color: $color-text-grey;
    }
  }

  &__icon-container {
    position: absolute;
    top: 50%;
    right: 8px;
    font-size: 24px;
    pointer-events: none;
    transform: translateY(-50%);

    #{$root}__select:disabled ~ & {
      .a-icon {
        color: $color-grey-light-3;
      }
    }
  }

  &__error {
    font-size: 12px;
    color: $color-primary-error;
  }

  &__input {
    width: 100%;

    &-container {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      max-width: 80px;
      margin-left: auto;
    }

    &__control {
      cursor: pointer !important;
      background: none !important;
      border-top-width: 0 !important;
      border-right-width: 0 !important;
      border-left-width: 0 !important;
      border-radius: 0 !important;
      box-shadow: none !important;

      &--is-focused {
        border-color: $color-line-gold !important;
      }

      @include pc() {
        #{$root}--dark & {
          border-color: $color-line-white !important;
        }
      }
    }

    &__single-value {
      font-family: $font-family-robinson;
      font-size: 18px;
      color: $color-text-gold !important;

      @include pc() {
        #{$root}--dark & {
          color: $color-text-white !important;
        }
      }
    }

    &__indicators {
      position: absolute;
      width: 100%;
      height: 100%;
    }

    &__indicator-separator {
      display: none;
    }

    &__dropdown-indicator {
      justify-content: flex-end;
      width: 100%;
      height: 100%;
      padding-right: 12px !important;
      font-size: 20px;

      .a-icon {
        @include pc() {
          #{$root}--dark & {
            color: $color-text-white;
          }
        }
      }
    }

    &__menu-list {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }

    &__menu {
      // border: 1px solid $color-line-gold !important;
      border: 0;
      border-radius: 0 !important;
    }

    &__value-container {
      padding-left: 12px !important;
      overflow: visible !important;
    }

    &__input {
      display: none !important;
    }

    &__option {
      font-family: $font-family-robinson;
      font-size: 18px;
      cursor: pointer !important;

      &--is-focused {
        background: rgba($color-grey-light-2, 0.3) !important;
      }
      &--is-selected {
        background: $color-base-gold !important;
      }
    }
  }
}
