.a-icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  vertical-align: middle;
  mask-type: alpha;
  mask-repeat: no-repeat;
  background-color: currentColor;
  background-repeat: no-repeat;
  mask-position: center;
  mask-size: contain;

  &--text-prefix {
    margin-right: 0.5em;
    vertical-align: -0.13em;
  }

  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) {
      mask-size: 100% 100%;
    }
  }

  @mixin icon-color($name, $color) {
    &--#{$name} {
      color: $color;
    }
  }

  @include icon-color('gold', $color-primary-gold);
  @include icon-color('blue', $color-primary-blue);
  @include icon-color('ivory', $color-primary-ivory);
  @include icon-color('white', $color-text-white);
  @include icon-color('black', $color-text-black);

  @mixin icon-shape($name) {
    &--#{$name} {
      mask-image: url('~images/icons/#{$name}.svg');
    }
  }

  @include icon-shape('all-floor');
  @include icon-shape('chevron-up');
  @include icon-shape('double-chevron-up');
  @include icon-shape('loading-area');
  @include icon-shape('pin');
  @include icon-shape('banquet');
  @include icon-shape('classroom');
  @include icon-shape('facebook');
  @include icon-shape('location-lg');
  @include icon-shape('plane');
  @include icon-shape('booth');
  @include icon-shape('clock');
  @include icon-shape('fax');
  @include icon-shape('location');
  @include icon-shape('reception');
  @include icon-shape('briefcase');
  @include icon-shape('close-sm');
  @include icon-shape('food-lg');
  @include icon-shape('mail');
  @include icon-shape('surrounding');
  @include icon-shape('bts');
  @include icon-shape('close');
  @include icon-shape('food-xl');
  @include icon-shape('map-lg');
  @include icon-shape('theatre');
  @include icon-shape('calendar');
  @include icon-shape('construction');
  @include icon-shape('food');
  @include icon-shape('map');
  @include icon-shape('topic-gold');
  @include icon-shape('car');
  @include icon-shape('contact');
  @include icon-shape('gallery-lg');
  @include icon-shape('mrt');
  @include icon-shape('topic-grey');
  @include icon-shape('chevron-down');
  @include icon-shape('documents');
  @include icon-shape('gallery');
  @include icon-shape('news');
  @include icon-shape('transport');
  @include icon-shape('chevron-left');
  @include icon-shape('double-chevron-down');
  @include icon-shape('hamburger');
  @include icon-shape('twitter');
  @include icon-shape('chevron-right-thin');
  @include icon-shape('double-chevron-left');
  @include icon-shape('information');
  @include icon-shape('pdf');
  @include icon-shape('website');
  @include icon-shape('chevron-right');
  @include icon-shape('double-chevron-right');
  @include icon-shape('link');
  @include icon-shape('phone');
  @include icon-shape('youtube');
  @include icon-shape('continue');
  @include icon-shape('preserve');
  @include icon-shape('build');
  @include icon-shape('square');
  @include icon-shape('megaphone');
  @include icon-shape('arrow-up');
  @include icon-shape('arrow-down');
  @include icon-shape('bus');
  @include icon-shape('parking');
  @include icon-shape('number-1');
  @include icon-shape('number-2');
  @include icon-shape('number-3');
  @include icon-shape('number-4');
  @include icon-shape('number-5');
  @include icon-shape('number-6');
  @include icon-shape('number-7');
  @include icon-shape('number-8');
  @include icon-shape('instagram');
  @include icon-shape('marker');
  @include icon-shape('weixin');
  @include icon-shape('line');
}
