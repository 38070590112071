.a-floor-selector-full-item {
  $root: &;

  display: flex;
  align-items: center;
  justify-content: flex-start;

  @include sp() {
    position: relative;
  }

  &--active {
    @include sp() {
      padding-bottom: 68px;
    }
  }

  &__figure {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    @include sp() {
      flex-direction: column;
      justify-self: flex-start;
    }
  }
  &__image {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 689px;
    height: 359px;

    @include tablet() {
      width: 500px;
    }

    @include sp() {
      order: 2;
      // width: 100%;
      // height: auto;
      width: 287px;
      height: 200px;
    }

    &-mask {
      position: absolute;
      left: 50%;
      z-index: 1;
      // opacity: 0;
      transform: translateX(-50%);

      @include tablet() {
        transform: translateX(-50%) scale(0.82);
      }

      #{$root}--active & {
        transform: translateX(-50%) scale(1.2);

        @include tablet() {
          transform: translateX(-50%) scale(0.82);
        }
      }

      @include sp() {
        display: none;
      }

      &--um {
        top: 22px;

        #{$root}--active & {
          top: 52px;
        }
      }

      &--lm {
        top: 10px;
        margin-left: -5px;

        #{$root}--active & {
          top: 40px;
        }
      }

      &--lg {
        top: 16px;

        #{$root}--active & {
          top: 44px;
        }
      }

      &--g {
        top: 4px;
        margin-left: -4px;

        #{$root}--active & {
          top: 32px;
        }
      }

      &--1 {
        top: 20px;

        #{$root}--active & {
          top: 48px;
        }
      }

      &--2 {
        top: 20px;

        #{$root}--active & {
          top: 50px;
        }
      }
    }

    &-holder {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 542px;
      height: 306px;
      transition: width 400ms;

      &--action {
        cursor: pointer;
      }

      @include tablet() {
        width: 400px !important;
      }

      /* #{$root}--active & {
        width: 689px;
        height: 356px;
        // margin-top: 40px;

        @include tablet() {
          width: 500px !important;
        }
      } */

      /* &--2 {
        width: 531px;
        height: 261px;

        #{$root}--active & {
          width: 645px;
          height: 319px;
        }
      }

      &--1 {
        width: 531px;
        height: 261px;

        #{$root}--active & {
          width: 645px;
          height: 319px;
        }
      }

      &--um {
        width: 531px;
        height: 261px;

        #{$root}--active & {
          width: 645px;
          height: 319px;
        }
      }

      &--g {
        width: 531px;
        height: 291px;

        #{$root}--active & {
          width: 645px;
          height: 349px;
        }
      }

      &--lm {
        width: 542px;
        height: 282px;

        #{$root}--active & {
          width: 656px;
          height: 340px;
        }
      }

      &--lg {
        width: 575px;
        height: 261px;

        #{$root}--active & {
          width: 689px;
          height: 319px;
        }
      } */

      @include sp() {
        width: 100%;
        // height: auto;
        height: 100%;

        #{$root}--active & {
          width: 100%;
          // height: auto;
          height: 100%;
        }
      }
    }

    img {
      position: absolute;
      width: 100%;
      max-width: 100%;
      height: auto;

      @include sp() {
        // position: static;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        height: auto;
        transform: translate3d(-50%, -50%, 0);

        #{$root}--active & {
          width: 100%;
          // transform: none;
        }
      }
    }
  }
  &__info {
    width: 240px;
    padding-left: 40px;
    margin-left: 0;

    @include sp() {
      order: 1;
      width: 100%;
      max-height: 0;
      padding-left: 0;
      overflow: hidden;
      opacity: 0;
      transition: opacity 400ms, max-height 250ms;

      #{$root}--active & {
        max-height: 80px;
        opacity: 1;
      }
    }
  }
  &__label {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-family: $font-family-robinson;
    font-weight: bold;
    color: $color-text-grey;
    transition: color 400ms;

    #{$root}--active & {
      flex-direction: column;
      align-items: flex-start;
      color: $color-text-gold;
    }

    @include sp() {
      flex-direction: column;
      align-items: center;

      #{$root}--active & {
        align-items: center;
      }
    }

    &-level {
      margin-right: 16px;
      font-size: 24px;
      line-height: 32px;
      white-space: nowrap;

      #{$root}--active & {
        margin-right: 0;
        margin-bottom: 16px;
      }

      @include sp() {
        margin-right: 0;
        font-size: 18px;
        line-height: 27px;

        #{$root}--active & {
          margin-bottom: 0;
        }
      }
    }
    &-name {
      font-size: 32px;
      line-height: 32px;
      white-space: nowrap;
      transition: font-size 400ms;

      #{$root}--active & {
        font-size: 128px;
        line-height: 128px;
      }

      @include tablet() {
        #{$root}--active & {
          font-size: 100px;
          line-height: 100px;
        }
      }

      @include sp() {
        font-size: 60px;
        line-height: 60px;

        #{$root}--active & {
          font-size: 60px;
          line-height: 60px;
        }
      }
    }
  }

  &__action {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    max-height: 0;
    overflow: hidden;
    opacity: 0;

    @include sp() {
      order: 3;
    }

    #{$root}--active & {
      max-height: 48px;
      margin-top: 35px;
      opacity: 1;
      transition: opacity 400ms 100ms, max-height 250ms 100ms;

      @include sp() {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
}
