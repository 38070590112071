.a-image-frame {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  max-width: 780px;
  height: 400px;
  overflow: hidden;

  @include sp() {
    height: 216px;
  }

  &__container {
    position: absolute;
    top: 0;
    right: 30px;
    z-index: 1;
    width: 100%;
    height: 350px;
    background: #ffffff;
    background-repeat: no-repeat;
    background-position: bottom center;
    background-size: cover;
    border: 1px solid;
    border-color: $color-line-gold;
    border-width: 1px;
    border-image-source: linear-gradient(
      231deg,
      rgba(184, 176, 0, 1) 0%,
      rgba(255, 231, 124, 1) 70%,
      rgba(130, 120, 0, 1) 100%
    );
    border-image-slice: 1;

    img {
      display: none;
    }

    @include sp() {
      right: 20px;
      max-width: 100%;
      height: 194px;
    }
  }

  &__frame {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 306px;
    height: 283px;
    border: 1px solid;
    border-color: $color-line-gold;
    border-width: 1px;
    border-image-source: linear-gradient(
      180deg,
      rgba(184, 176, 0, 1) 0%,
      rgba(255, 231, 124, 1) 50%,
      rgba(130, 120, 0, 1) 100%
    );
    border-image-slice: 1;

    @include sp() {
      width: 200px;
      height: 186px;
    }
  }
}
