.m-tabs {
  $root: &;

  --length: 1;
  --active-index: 0;

  width: 100%;

  &__tab-header {
    position: relative;
    @include sp() {
      overflow-x: scroll;
      overflow-y: hidden;
    }
  }

  &__tab-list {
    display: flex;
  }

  &__tab {
    display: flex;
    flex: 1 1 100%;
    align-items: center;
    justify-content: center;
    min-width: 90px;
    // padding: 10px 0;
    font-family: $font-family-robinson;
    font-size: 14px;
    // font-weight: xd-font-weight('regular');
    line-height: 20px;
    text-align: center;
    cursor: pointer;

    &--active {
      // font-weight: xd-font-weight('medium');
    }

    #{$root}--floor & {
      .a-tab-item {
        &__icon {
          margin-bottom: 4px;
          font-size: 32px;
        }
        &__text {
          height: 35px;
          font-size: 14px;
          font-weight: normal;
          line-height: 1.3;
        }
      }
    }
  }

  &__indicator {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    min-width: calc(var(--length) * 90px);
    height: 4px;
    background-color: $color-grey-light-2;

    &::after {
      position: absolute;
      bottom: 0;
      left: calc(100% / var(--length) * var(--active-index));
      width: calc(100% / var(--length));
      height: 4px;
      content: '';
      background-color: $color-primary-gold;
      border-radius: 2px;
      transition-duration: 0.3s;
      transition-property: left;
    }

    &--disabled {
      &::after {
        content: none;
      }
    }
  }

  &__content {
    display: none;
    margin-top: 32px;

    @include tabletOrMore {
      padding-right: 30px;
      padding-left: 30px;
    }

    &--active {
      display: block;
    }
  }
}
