.a-pagination {
  $root: &;

  display: flex;
  align-items: center;
  height: 40px;

  &__action {
    display: flex;
    align-items: center;
    height: 100%;
    font-size: 24px;
    color: $color-primary-gold;
    cursor: pointer;

    &--prev {
      padding: 0 10px 0 24px;
      border-radius: 24px 0 0 24px;
    }

    &--next {
      padding: 0 24px 0 10px;
      border-radius: 0 24px 24px 0;
    }

    &--disabled,
    &:disabled {
      .a-icon {
        color: $color-text-grey;
        cursor: not-allowed;
      }
    }
  }

  &__pages {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    height: 100%;
    margin: 0 5px;
  }

  &__page-item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 100%;
    font-size: 14px;
    // font-weight: xd-font-weight('light');
    color: $color-text-black;
    cursor: pointer;

    &--active {
      // font-weight: xd-font-weight('semibold');
      color: $color-text-white;
      pointer-events: none;
    }

    &--ellipsis {
      pointer-events: none;
    }
  }

  &__page-index {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;

    #{$root}__page-item--active & {
      background-color: $color-primary-gold;
      border-radius: 50%;
    }
    #{$root}__page-item--ellipsis & {
      top: -4px;
    }
  }
}
