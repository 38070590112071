.t-hotel-surrounding {
  $root: &;

  width: 100;

  .a-heading {
    .a-heading__title {
      letter-spacing: 8px;
    }
  }

  &__description {
    max-width: 730px;
    margin-top: 64px;
    margin-right: auto;
    margin-left: auto;
    text-align: center;

    #{$root}--en & {
      max-width: 826px;
    }

    @include tablet() {
      max-width: 600px;

      #{$root}--en & {
        max-width: 680px;
      }
    }

    @include sp() {
      max-width: 315px;
      margin-top: 32px;

      #{$root}--en & {
        max-width: 291px;
      }
    }

    &--hotel {
      #{$root}--en & {
        max-width: 750px;
      }
      @include tablet() {
        max-width: 600px;

        #{$root}--en & {
          max-width: 656px;
        }
      }

      @include sp() {
        max-width: 324px;

        #{$root}--en & {
          max-width: 291px;
        }
      }

      /* .m-poi-card-list {
        .a-poi-card {
          &--v {
            &-modena-by-fraser-bangkok {
              .a-poi-card__summary {
                #{$root}--en & {
                  @include sp() {

                  }
                }
              }
            }
          }
        }
      } */
    }
  }

  &__tabs {
    margin-top: 32px;
  }

  &__info {
    padding-bottom: 120px;

    @include sp() {
      padding-bottom: 64px;
    }

    .m-poi-card-list {
      @include sp() {
        .a-poi-card {
          width: 100%;
          margin-right: 0;
          margin-left: 0;

          .a-poi-card__image {
            width: 100%;

            img {
              position: relative;
              top: 50%;
              width: 100%;
              height: auto;
              transform: translateY(-50%);
            }
          }

          .a-poi-card__title {
            height: auto;
            min-height: 70px;
            padding-top: 10px;
            padding-bottom: 10px;
            word-wrap: break-word;
          }
        }
      }
    }

    &--hotel {
      // max-width: none;
      max-width: 1060px;
      margin-top: 64px;

      @include sp() {
        margin-top: 20px;
      }
    }

    &-hotel {
      max-width: 1016px;
      margin-top: 32px;
      margin-right: auto;
      margin-left: auto;

      &-carousel {
        margin-top: 44px;

        @include sp() {
          margin-right: -15px;
          margin-bottom: 64px;
          margin-left: -15px;
        }
      }
    }

    &--surrounding {
      // max-width: none;
      max-width: 1060px;
      margin-top: 64px;

      @include sp() {
        margin-top: 20px;
      }
    }

    &-surrounding {
      max-width: 1016px;
      margin-top: 32px;
      margin-right: auto;
      margin-left: auto;

      &-carousel {
        margin-top: 44px;

        @include sp() {
          margin-right: -15px;
          margin-bottom: 64px;
          margin-left: -15px;
        }
      }
    }
  }
}
