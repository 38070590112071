.m-shortcut-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;

  @include sp() {
    flex-direction: column;
    height: 100%;

    .a-shortcut-item {
      padding-top: 12px;
      padding-bottom: 12px;
      border-bottom: 1px solid $color-grey-light-2;
    }
  }
}
