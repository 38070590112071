.m-location-info {
  display: flex;

  @include sp() {
    flex-direction: column;
  }

  &__info {
    display: flex;
    flex-direction: column;

    &--left {
      flex: 2;
    }
    &--right {
      flex: 1;
    }

    .a-location-info-item {
      margin-bottom: 8px;
    }
  }
}
