.a-heading {
  $root: &;

  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 70px;
  padding-bottom: 50px;
  text-align: center;

  @include sp() {
    padding-top: 64px;
    padding-bottom: 36px;
  }

  &::before {
    position: absolute;
    top: 0;
    left: 50%;
    width: 1px;
    height: 48px;
    content: '';
    background: rgba($color-primary-gold, 0.2);
    transform: translateX(-50%);
  }

  &::after {
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 14px;
    height: 32px;
    content: '';
    background-image: url('~images/diamond-flat-blue.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    transform: translateX(-50%);
  }

  &--hero {
    padding-top: 0;
    padding-bottom: 0;

    &#{$root}::before,
    &#{$root}::after {
      content: none;
    }
  }

  &--welcome {
    padding-top: 170px;
    padding-bottom: 40px;

    @include sp() {
      padding-top: 40px;
      padding-bottom: 0;
    }

    &#{$root}::before {
      height: 90px;

      @include sp() {
        height: 25px;
      }
    }

    &#{$root}::after {
      width: 8px;
      height: 16px;

      @include sp() {
        content: none;
      }
    }
  }

  &__title {
    font-size: 40px;
    line-height: 1.3;
    color: $color-text-gold;
    text-transform: uppercase;
    letter-spacing: 8px;

    #{$root}--th & {
      letter-spacing: 0;
    }

    @include sp() {
      padding-right: 0;
      padding-left: 0;
      font-size: 32px;
      // line-height: 1.3;
      // letter-spacing: 2px;
    }

    #{$root}--hero &,
    #{$root}--welcome & {
      font-size: 56px;
      line-height: 56px;
      color: $color-text-white;
      text-shadow: 0 2px 4px rgba($color-dark-gold, 0.3);
      letter-spacing: 8px;

      @include sp() {
        font-size: 24px;
        line-height: 33px;
      }
    }

    #{$root}__subtitle-top {
      display: block;
      margin-bottom: 10px;
      font-size: 30px;
      font-weight: 500;
      line-height: 1.3;
      color: $color-text-ivory;
      // letter-spacing: 12px;

      @include sp() {
        margin-bottom: 5px;
        font-size: 22px;
        line-height: 22px;
        // letter-spacing: 7px;
      }
    }

    #{$root}--welcome & {
      color: $color-text-gold;
      text-shadow: none;
    }
  }

  &__subtitle {
    // text-transform: uppercase;
    // letter-spacing: 4px;
    text-transform: none;

    @include sp() {
      // letter-spacing: 2px;
    }

    #{$root}--hero &,
    #{$root}--welcome & {
      margin-top: 12px;
      font-size: 30px;
      line-height: 45px;
      color: $color-text-white;
      text-shadow: 0 2px 4px rgba($color-dark-gold, 0.3);

      @include sp() {
        font-size: 14px;
        line-height: 33px;
      }
    }

    #{$root}--welcome & {
      color: $color-text-gold;
      text-shadow: none;
    }
  }
}
