.a-contruction-progress-bar {
  position: relative;
  display: flex;
  width: 100%;
  background: $color-base-white;

  &__base {
    position: relative;
    display: flex;
    flex: 1;
    align-items: flex-end;

    &::after {
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 0;
      width: 100%;
      height: 3px;
      content: '';
      background: $color-primary-gold;

      @include sp() {
        z-index: 1;
      }
    }

    @include sp() {
      height: 290px;
    }

    &-image {
      position: relative;
      bottom: 0;
      left: 0;
      width: 100%;
      max-width: 100%;
      height: auto;

      @include sp() {
        position: absolute;
        bottom: 0;
        left: 50%;
        width: auto;
        max-width: none;
        height: 290px;
        transform: translate3d(-50%, 0, 0);
      }
    }
  }

  &__progress {
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: flex-end;
    width: 100%;

    &-shadow {
      position: absolute;
      right: 0;
      bottom: 0;
      z-index: 2;
      width: 30px;
      height: calc(60% - 3px);
      background: none;
      background: linear-gradient(270deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
      // opacity: 0.8;
      transition: left 400ms, opacity 400ms;
      // transform: translateX(-30px);
      transform: translate3d(-29px, 0, 0.0001px);

      &--done {
        opacity: 0;
      }

      @include sp() {
        width: 20px;
        // opacity: 0.5;
        transform: translate3d(-19px, 0, 0.0001px);
      }
    }

    &-holder {
      position: relative;
      flex: 1;
      overflow: hidden;
      background: linear-gradient(0deg, rgba(211, 229, 240, 1) 0%, rgba(255, 255, 255, 0) 60%);
      transition: clip-path 400ms;
      transform: translateZ(0.0001px);

      @include sp() {
        height: 290px;
      }
    }
    &-image {
      width: 100vw;
      max-width: 100vw;
      height: auto;

      @include sp() {
        position: absolute;
        bottom: 0;
        left: 50%;
        width: auto;
        max-width: none;
        height: 290px;
        transform: translate3d(-50%, 0, 0);
      }
    }
  }
}
