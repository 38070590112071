.m-contruction-progress {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__info {
    position: absolute;
    top: 0;
    left: 50%;
    z-index: 1;
    transform: translateX(-50%);

    @include sp() {
      position: static;
      width: 100%;
      padding: 0 16px;
      margin-bottom: 20px;
      transform: none;
    }
  }

  &__bar {
    position: relative;
    z-index: 0;
    display: flex;
    width: 100%;
    min-height: 240px;

    @include sp() {
      align-items: flex-end;
      justify-content: center;
      height: 290px;
      min-height: 0;
      margin-top: -120px;
      overflow: hidden;
    }
  }
}
