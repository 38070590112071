/* 下拉按钮样式 */
.dropbtn {
  // background-color: #4CAF50;
  // color: white;
  // padding: 16px;
  border: none;
  cursor: pointer;
  display: block;
}

/* 容器 <div> - 需要定位下拉内容 */
.dropdown {
  position: relative;
  display: inline-block;
}

/* 下拉内容 (默认隐藏) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #858888;
  // background-color: #fff;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}

/* 下拉菜单的链接 */
 .dropdown-content a {
  color: black  ;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* 鼠标移上去后修改下拉菜单链接颜色 */
.dropdown .dropdown-content a:hover {
  background-color: #34a2ec
  // background-color: #827800;
}

/* 在鼠标移上去后显示下拉菜单 */
// .dropdown:hover .dropdown-content {
//   display: block;
// }

.o-global-header__item:hover .dropdown-content {
  display: block;
}








.o-global-header {
  $root: &;

  position: fixed;
  top: 0;
  left: 0;
  z-index: $zindex-globalheader;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-right: 16px;
  padding-left: 16px;
  background: $color-base-white;
  box-shadow: 0 2px 4px rgba($color-grey-light-4, 0.5);

  @include tabletOrLess() {
    height: 48px;
    padding-right: 0;
    padding-left: 0;
  }

  &--socials {
    padding-bottom: 56px;

    @include tabletOrLess() {
      padding-bottom: 0;
    }
  }

  &--transparent {
    @include pc() {
      background: none;
      box-shadow: none;
    }
  }

  &__container {
    position: relative;
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 1224px;
    padding: 16px 0;

    @include tabletOrLess() {
      height: 48px;
    }

    #{$root}--socials & {
      @include pc() {
        border-bottom: 1px solid $color-line-gold;
      }
    }

    #{$root}--dark#{$root}--socials & {
      border-color: $color-line-white;
    }

    @include tabletOrLess() {
      flex-direction: column;
      padding: 0;
    }
  }

  &__main {
    display: flex;
    flex: 100px 0 0;

    @include tabletOrLess() {
      z-index: 1;
      flex: auto;
      padding-top: 4px;
      padding-bottom: 4px;
      box-shadow: 0 2px 4px rgba($color-grey-light-4, 0.5);
    }
  }

  &__nav-toggle {
    display: flex;
    align-items: center;
    justify-content: center;

    @include tabletOrLess() {
      position: absolute;
      top: 0;
      left: 0;
      width: 48px;
      height: 48px;
    }

    &-button {
      width: 48px;
      height: 48px;
      cursor: pointer;

      .a-icon {
        width: 24px;
        height: 24px;
      }
    }

    @include pc() {
      display: none;
    }
  }

  &__brand {
    display: flex;
    align-items: center;
    justify-content: center;

    @include tabletOrLess() {
      margin-right: auto;
      margin-left: auto;
    }

    &-logo {
      @include tabletOrLess() {
        display: none;
      }
    }

    &-logo-sm {
      @include pc() {
        display: none;
      }
    }
  }

  &__side {
    display: flex;
    width: 100%;
    // background: $color-base-white;

    @include tabletOrLess() {
      // display: none;
      position: fixed;
      top: 48px;
      left: 0;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      max-height: 0;
      overflow: hidden;
      visibility: hidden;
      opacity: 0;
      transition: max-height 700ms, opacity 700ms, visibility 0s linear 1s;

      &--expand {
        // position: fixed;
        display: flex;
        width: 100%;
        height: 100vh;
        max-height: 100vh;
        visibility: visible;
        background: $color-base-white;
        opacity: 1;
        transition-delay: 0s;
      }
    }
  }

  &__navigation {
    display: flex;
    align-items: stretch;
    justify-content: center;
    margin-right: auto;
    margin-left: auto;

    @include tabletOrLess() {
      flex-direction: column;
      margin-right: 0;
      margin-left: 0;
      background: $color-base-white;
    }
  }

  &__navigation-inner {
    display: flex;
    align-items: center;
    justify-content: center;

    @include tabletOrLess() {
      flex-direction: column;
      align-items: stretch;
      width: 100%;
    }
  }

  &__item {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 4px;
    padding-bottom: 4px;
    margin-right: 16px;
    margin-left: 16px;
    font-family: $font-family-robinson;
    font-size: 18px;

    @include tabletOrLess() {
      display: flex;
      padding: 0;
      margin: 0;
      border-bottom: 1px solid rgba($color-primary-ivory, 0.4);
    }

    a {
      // color: $color-text-grey;
      color: #595857;
      transition: color 400ms;

      @include pc() {
        #{$root}--dark & {
          color: $color-text-white;
        }
      }

      @include tabletOrLess() {
        display: block;
        width: 100%;
        padding: 13px 16px;
      }
    }

    &::after {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 2px;
      content: '';
      background: $color-base-gold;
      opacity: 0;
      transition: opacity 400ms;

      @include pc() {
        #{$root}--dark & {
          background: $color-base-white;
        }
      }
    }

    &--active,
    &:hover {
      &::after {
        opacity: 1;

        @include tabletOrLess() {
          display: none;
        }
      }

      a {
        color: $color-text-gold;
        text-decoration: none;

        @include pc() {
          #{$root}--dark & {
            color: $color-text-white;
          }
        }
      }
    }
  }

  &__additional {
    display: flex;
    min-width: 80px;

    @include tabletOrLess() {
      padding: 32px 16px;
      background: $color-base-white;
    }
  }

  &__language-selector {
    width: 100%;

    &-container {
      display: flex;
      // display: none;
      align-items: center;
      justify-content: center;
      width: 100%;
      max-width: 80px;
      margin-left: auto;

      @include sp() {
        margin-bottom: 90px;
      }
    }

    &__control,
    &__control--is-focused {
      cursor: pointer !important;
      background: none !important;
      border-color: $color-line-gold  !important;
      border-radius: 0 !important;
      box-shadow: none !important;

      @include pc() {
        #{$root}--dark & {
          border-color: $color-line-white  !important;
        }
      }
    }

    &__single-value {
      font-family: $font-family-robinson;
      font-size: 18px;
      color: $color-text-gold  !important;

      @include pc() {
        #{$root}--dark & {
          color: $color-text-white  !important;
        }
      }
    }

    &__indicators {
      position: absolute;
      width: 100%;
      height: 100%;
    }

    &__indicator-separator {
      display: none;
    }

    &__dropdown-indicator {
      justify-content: flex-end;
      width: 100%;
      height: 100%;
      padding-right: 12px !important;
      font-size: 20px;

      .a-icon {
        @include pc() {
          #{$root}--dark & {
            color: $color-text-white;
          }
        }
      }
    }

    &__menu-list {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }

    &__menu {
      border: 1px solid $color-line-gold  !important;
      border-radius: 0 !important;
    }

    &__value-container {
      padding-left: 12px !important;
      overflow: visible !important;
    }

    &__input {
      display: none !important;
    }

    &__option {
      font-family: $font-family-robinson;
      font-size: 18px;
      cursor: pointer !important;

      &--is-focused {
        background: rgba($color-grey-light-2, 0.3) !important;
      }

      &--is-selected {
        background: $color-base-gold  !important;
      }
    }
  }

  &__social-networks {
    position: absolute;
    right: 0;
    bottom: -56px;
    display: flex;

    @include tabletOrLess() {
      position: static;
    }
  }

  &__social-item {
    &:not(:first-child) {
      margin-left: -1px;
    }

    .a-button {
      @include pc() {
        #{$root}--dark & {
          border-color: $color-line-white;
        }
      }

      &--icon {
        .a-icon {
          @include pc() {
            #{$root}--dark & {
              color: $color-text-white;
            }
          }
        }
      }
    }
  }
}