.t-default {
  position: relative;
  padding-top: 97px;

  @include tabletOrLess() {
    padding-top: 48px;
  }

  @include sp() {
    padding-top: 48px;
  }

  &__main {
    position: relative;
  }

  &__hero-banner {
    width: 100%;
    height: 256px;
    background-color: rgba($color-base-gold, 0.2);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  &__content {
    position: relative;
    z-index: 1;
    width: 100%;
    padding-right: 24px;
    padding-left: 24px;

    @include sp() {
      padding-right: 24px;
      padding-left: 24px;
    }
  }

  &__subscribe-box {
    width: 100%;
  }

  &__back-to-top {
    position: fixed;
    right: 0;
    bottom: 0;
    z-index: $zindex-chat;

    &--stick {
      position: absolute;
      bottom: 430px;

      @include sp() {
        display: none;
      }
    }
  }

  // DECORATION
  &__deco-1 {
    position: absolute;
    top: 256px;
    left: 0;
    z-index: 0;
    width: 154px;
    height: 273px;
    background: url('~images/page-top-pattern.svg');
    background-repeat: no-repeat;
    background-position: bottom right;
    background-size: 100%;
    @include sp() {
      display: none;
    }
  }
  &__deco-2 {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 0;
    width: 212px;
    height: 614px;
    background: url('~images/page-bottom-pattern.svg');
    background-repeat: no-repeat;
    background-position: bottom right;
    background-size: 100%;

    @include sp() {
      display: none;
    }
  }
}
