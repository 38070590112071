.a-contact-info-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  line-height: 33px;
  color: $color-text-gold;
  // text-transform: uppercase;
  // letter-spacing: 3.76px;

  @include sp() {
    font-size: 18px;
    line-height: 27px;
    // letter-spacing: 1.3px;
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 8px;

    .a-icon {
      width: 40px;
      height: 40px;
    }
  }
}
