.a-text {
  &--subtitle {
    font-size: 18px;
    line-height: 27px;

    @include sp() {
      font-size: 14px;
    }
  }

  &--exceprt {
    font-family: $font-family-robinson;
    font-size: 23px;
    line-height: 31px;
    color: $color-grey-dark-1;

    @include sp() {
      font-size: 18px;
      line-height: 27px;
    }
  }

  &--align {
    &-left {
      text-align: left;
    }
    &-center {
      text-align: center;
    }
    &-right {
      text-align: right;
    }
  }
}
