.p-app {
  &__loader {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    width: 100vw;
    height: 100vh;
  }
}
.a-list {
  li {
    position: relative;
    padding-left: 20px;
    margin: 0;

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      content: '◆';
    }
  }
}

// -- COOKIE WOW
.cwc-sdk-container {
  display: none;
  .cwc-cookie-banner-ui-sdk {
    font-family: $font-family-bai-jamjuree;

    a,
    span,
    button {
      font-family: $font-family-bai-jamjuree;
    }

    .cwc-float-container {
      padding: 0;
      background: $color-base-gold;

      @include sp() {
        padding-top: 16px;
        padding-bottom: 16px;
      }

      .cwc-banner-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        max-width: none;
        max-width: 1225px;
        height: 120px;
        padding: 10px 0;
        background: none;
        border: 0;
        border-radius: 0;
        box-shadow: none;

        @include sp() {
          height: 168px;
        }

        .cwc-banner-close-button {
          position: absolute;
          top: 16px;
          right: 0;
          z-index: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 40px !important;
          height: 40px !important;

          svg {
            width: 16px;
            height: 16px;
            color: $color-text-white;
          }
        }

        .cwc-banner-content {
          position: absolute;
          top: 50%;
          left: 0;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          width: 100%;
          padding: 16px 56px 16px 16px;
          transform: translateY(-50%);

          @include sp() {
            flex-direction: column;
          }

          .cwc-banner-content-main {
            width: 100%;
            font-family: $font-family-bai-jamjuree;
            font-size: 12px;
            color: $color-text-white;

            .cwc-banner-title {
              display: none;
            }

            .cwc-banner-policy-link {
              color: $color-text-white;
            }
          }
          .cwc-banner-content-aside {
            flex: 0 0 160px;
            flex-direction: column;
            width: 100%;
            margin-left: 20px;

            @include sp() {
              flex: 1;
              flex-direction: row;
              padding-top: 20px;
            }

            .cwc-accept-button-wrapper {
              margin: 0;

              @include sp() {
                padding: 0;
              }

              .cwc-accept-button {
                width: 150px;
                font-family: $font-family-robinson;
                font-size: 18px;
                color: $color-text-white;
                white-space: nowrap;
                background: none;
                border: 1px solid $color-line-white;
                border-radius: 0;
              }
            }

            .cwc-setting-button-wrapper {
              @include sp() {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
              }
              .cwc-setting-button {
                font-size: 12px;
                color: $color-text-white;
              }
            }
          }
        }

        /* & > div:last-child {
          flex: 0;
          transform: scale(0);
        } */
      }
    }
    .cwc-modal-container {
      .cwc-preference-container {
        .cwc-preference-header {
          span {
            font-family: $font-family-robinson;
            color: $color-text-gold;
          }
        }

        button {
          font-family: $font-family-robinson;
          font-size: 18px;
          color: $color-text-white;
          background: $color-base-gold;
          border-radius: 0;
        }

        /* & > div:nth-child(2) {
          transform: scale(0);
        } */

        & > button:last-child {
          background: none;
        }
      }
    }
  }
}

.p-thankyou-modal {
  z-index: 999;
  display: flex;
  align-content: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
  }

  &__content {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;
    width: 100%;
    max-width: 450px;
    min-height: 180px;
    height: 420px;
    // max-height: 600px;
    text-align: center;
    background: $color-base-white;
    transform: translate3d(-50%, -50%, 0);
    border-radius: 70px 0 70px 0;
    border: 2px solid $color-primary-gold;
    display: flex;
    flex-direction: column;
    justify-content: center;
    // padding: 20px;
    // background: url('~images/qsncc-adv.png') no-repeat;
    // background-size: cover;
    &-logo{
      margin: 5px 0;
    }

    &-icon {
      margin: 20px 0;
    }

    &-title {
      // margin-top: 20px;
      font-family: $font-family-robinson;
      font-size: 18px;
      line-height: 27px;
      color: $color-text-gold;
    }
    &-description {
      margin-top: 8px;
      font-size: 14px;
      line-height: 20px;
      // color: #fff;
    }
    &-button {
      Button{
        border: none !important;
        padding: 0 10px 10px 10px;
        &:hover{
          background-color: $color-primary-ivory;
        }
      }
      display: flex;
      justify-content: end;
      padding: 0 30px ;
    }
  }
}
.p-splash-page {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background: url('~images/pattern.png');
  background-repeat: repeat;

  @include tabletOrLess() {
    padding: 24px;
    img {
      width: 100%;
      max-width: 325px;
      height: auto;
    }
  }
}
.venue-img:hover img{
  opacity: 0;
}
.venue-img .text_hover{
  opacity: 0;
}
.venue-img:hover .text_hover{
  transition: all .4s ease-out;
  opacity: 1;
  transform: translateY(-7em);
  -webkit-transform: translateY(-7em);
  -moz-transform: translateY(-7em);
  -ms-transform: translateY(-7em);
  -o-transform: translateY(-7em);
}
.SpaceDay{
  display: flex;
  align-items: center;
  justify-content: center;
  p{
    flex: 1;
  }
  &_info{
    margin-right: 2rem;
    .surapol{
      width: 70%;
    }
  }
}

