.a-shortcut-item {
  $root: &;

  display: flex;
  align-items: center;
  justify-content: center;

  @include sp() {
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  &__button {
    cursor: pointer;
  }

  &__title {
    font-size: 24px;
    line-height: 32px;
    color: $color-text-gold;
    text-transform: uppercase;
    // letter-spacing: 2px;

    @include sp() {
      font-size: 18px;
      line-height: 27px;
    }
  }
  &__subtitle {
    font-family: $font-family-bai-jamjuree;
    font-size: 12px;
    line-height: 18px;
    color: $color-text-grey;
  }
  &__arrow {
    position: relative;
    max-height: 0;
    margin-top: 4px;
    overflow: hidden;
    opacity: 0;
    transition: max-height 400ms, opacity 500ms;

    @include sp() {
      max-height: 20px;
      opacity: 1;

      img {
        height: 12px;
      }
    }
  }

  &:hover,
  &--active {
    @include tabletOrMore {
      #{$root}__arrow {
        max-height: 30px;
        opacity: 1;
      }
    }
  }
}
